import React, { useState, useEffect, useCallback } from "react";
import "./DatePicker.css";
import down_arrow from "./../../assets/icons/down-arrow.svg"
import left_arrow from "./../../assets/icons/left_arrow.svg"
import right_arrow from "./../../assets/icons/right_arrow.svg"
import { useSecondHeader } from "../../contexts/SecondHeaderContext";
import { useTranslation } from 'react-i18next';

const DatePicker = ({children, onDateSelected, horizontalAnchor, type, defaultTime = new Date()}) => {
    const { t } = useTranslation();
    const { currentUnit, firstUse } = useSecondHeader();
    const TimeUnit = {
        YEAR: "year",
        MONTH: "month",
        WEEK: "week"
    }

    const [selectedTimeLabel, setSelectedTimeLabel] = useState("");

    // The highlightedTime defaults to the current year.
    // TODO: Default to currently selected time, if any.
    const defaultTimeUnit = type === "week" ? TimeUnit.WEEK : TimeUnit.YEAR;
    const [highlightedTime, setHighlightedTime] = useState(defaultTime);
    const [highlightedTimeUnit, setHighlightedTimeUnit] = useState(defaultTimeUnit);
    const [shownYear, setShownYear] = useState(defaultTime.getFullYear());
    
    // The selected time defaults to the current year.
    const [selectedTime, setSelectedTime] = useState(defaultTime);
    const [selectedTimeUnit, setSelectedTimeUnit] = useState(defaultTimeUnit);

    const [expanded, setExpanded] = useState(false);

    const months = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"];

    const weekColumns = 9;

    const closePicker = () => {
        setExpanded(false);
    }


    const getWrapperClass = () => {
        if (horizontalAnchor === "right") {
            return "right-anchored";
        }

        return "default-anchored";
    }

    const showSelectedTime = () => {
        setShownYear(selectedTime.getFullYear());
        setHighlightedTime(selectedTime);
        setHighlightedTimeUnit(selectedTimeUnit);
    }

    const calculateSelectedTimeLabel = (time, unit) => {
        const yearToDateLabel = t("ÅTD");

        var label = "";

        var _time = time ? time : defaultTime;
        var _unit = unit ? unit : defaultTimeUnit;

        const year = _time.getFullYear();

        switch (_unit) {
            case TimeUnit.YEAR:
                label = year === (new Date()).getFullYear() ? yearToDateLabel : year;
                break;
            case TimeUnit.MONTH:
                label = year + " " + months[_time.getMonth()];
                break;
            case TimeUnit.WEEK:
                label = year + " " + t("uge") + " " + getWeekNumber(_time);
                break;
            default:
                break;
        }

        return label;
    }

    const toggleClicked = useCallback(() => {
        ////console.log("toggleClicked");
        if (expanded) {
            closePicker();
        } else {
            setExpanded(true);
        }
    }, [expanded]);

    // year: Date
    const weeksInYear = useCallback(() => {
        return 52 // TODO: get correct number of weeks in year.
    }, []);

    const weekMatrix = () => {
        var output = [[]];
        const total_weeks = weeksInYear();
        const items = Math.ceil(weeksInYear() / weekColumns) * weekColumns;
        for (var i = 0; i < items; i++) {
            const week = (i < total_weeks) ? i + 1 : "";
            if(output[output.length - 1].length === weekColumns) {
                output.push([week]);
            } else {
                output[output.length - 1].push(week);
            }
        }
        
        return output;
    }

    const getYearClass = () => {
        if (highlightedTimeUnit === TimeUnit.YEAR
            && shownYear === highlightedTime.getFullYear()
        ) {
            return "selected-year";
        } else {
            return "year";
        }
    }

    const boundedSetYear = (potentialValue) => {
        var withinLowerBound = true
        if (firstUse) {
            const firstUseYear = firstUse.getFullYear();
            withinLowerBound = potentialValue >= firstUseYear
        }
        
        const currentYear = new Date().getFullYear();
        const withinUpperBound = potentialValue <= currentYear;

        if (withinLowerBound && withinUpperBound) {
            setShownYear(potentialValue);
        }
    }

    const prevYearClicked = () => {  
        boundedSetYear(shownYear - 1)
    }

    const nextYearClicked = () => {
        boundedSetYear(shownYear + 1);
    }

    const yearClicked = () => {
        if (type !== "week") {
            setHighlightedTimeUnit(TimeUnit.YEAR);
            setHighlightedTime(new Date(shownYear, 0, 1));
        }
    }

    const getSwitchClass = (timeUnit) => {
        if(type === "week" && timeUnit === TimeUnit.WEEK) {
            return "selected-switch-button";
        }

        if (highlightedTimeUnit === timeUnit
            && shownYear === highlightedTime.getFullYear()
        ) {
            return "selected-switch-button";
        }

        return "switch-button";
    }

    const monthSwitchClicked = () => {
        setHighlightedTimeUnit(TimeUnit.MONTH);
        if (highlightedTime.getFullYear() !== shownYear) {
            setHighlightedTime(new Date(shownYear, 0, 1));
        }
    }

    const weekSwitchClicked = () => {
        if (type === "week") {
            return;
        }
        setHighlightedTimeUnit(TimeUnit.WEEK);
        if (highlightedTime.getFullYear() !== shownYear) {
            setHighlightedTime(new Date(shownYear, 0, 1));
        }
    }

    const monthButtonClicked = (month) => {
        var newDate = new Date(shownYear, months.indexOf(month), 1);
        setHighlightedTime(newDate);
        setHighlightedTimeUnit(TimeUnit.MONTH);
    }

    const isMonthActive = (monthNumber) => {
        var active = true;

        if(firstUse 
            && monthNumber < firstUse.getMonth()
            && shownYear <= firstUse.getFullYear()
        ) {
            active = false;
        }

        if (monthNumber > (new Date()).getMonth()
            && shownYear >= (new Date()).getFullYear()
        ) {
            active = false;
        }

        return active
    }


    const getMonthClass = (month) => {
        const monthNumber = months.indexOf(month);
        if(!isMonthActive(monthNumber)) {
            return "inactive-month-button";
        }

        if ((highlightedTimeUnit === TimeUnit.MONTH) 
            && monthNumber === highlightedTime.getMonth()
            && shownYear === highlightedTime.getFullYear()
        ) {
            return "selected-month-button";
        } else {
            return "month-button";
        }
    }

    const getWeekTowerClass = () => {
        if(type === "week") {
            return "week-only-week-tower";
        }

        return "week-tower";
    }

    const weekButtonClicked = (week) => {
        setHighlightedTime(new Date(shownYear, 0, 1 + (parseInt(week) - 1) * 7));
        setHighlightedTimeUnit(TimeUnit.WEEK);
    }

    const getWeekNumber = (date) => {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    }

    const isWeekActive = (weekNumber) => {
        var active = true;

        if(firstUse 
            && weekNumber < getWeekNumber(firstUse) 
            && shownYear <= firstUse.getFullYear()
        ) {
            active = false;
        }

        if (weekNumber > getWeekNumber(new Date()) 
            && shownYear >= (new Date()).getFullYear()
        ) {
            active = false;
        }

        return active
    }

    const getWeekClass = (week) => {
        if (!isWeekActive(parseInt(week))) {
            return "inactive-week-button";
        }

        if ((highlightedTimeUnit === TimeUnit.WEEK) 
            && parseInt(week) === getWeekNumber(highlightedTime)
            && shownYear === highlightedTime.getFullYear()
        ) {
            return "selected-week-button";
        }

        return "week-button";
    }

    const onSaveClicked = () => {
        const selectedTimeNewValue = highlightedTime
        const selectedTimeUnitNewValue = highlightedTimeUnit

        setSelectedTime(selectedTimeNewValue);
        setSelectedTimeUnit(selectedTimeUnitNewValue);

        setExpanded(false);
    }

    const ytdActionClicked = () => {
        const currentYear = (new Date()).getFullYear();
        setHighlightedTimeUnit(TimeUnit.YEAR);
        setHighlightedTime(new Date(currentYear, 0, 1));
        setShownYear(currentYear);
    }

    const cancelClicked = () => {
        closePicker();
    }

    const prevClicked = () => {
        var newDate;
        switch (selectedTimeUnit) {
            case TimeUnit.YEAR:
                newDate = new Date(selectedTime.getFullYear() - 1, 0, 1);
                if (newDate.getFullYear() >= (firstUse).getFullYear()) {
                    setSelectedTime(newDate);
                }
            break;
            case TimeUnit.MONTH:
                newDate = (new Date(selectedTime.getFullYear(), selectedTime.getMonth() - 1, 1));
                if (
                    newDate.getFullYear() > (firstUse).getFullYear() 
                    || newDate.getMonth() >= (firstUse).getMonth()
                ) {
                    setSelectedTime(newDate);
                }
            break;
            case TimeUnit.WEEK:
                newDate = (new Date(selectedTime.getFullYear(), 0, 1 + (getWeekNumber(selectedTime) - 2) * 7));
                if(
                    newDate.getFullYear() > (firstUse).getFullYear() ||
                    getWeekNumber(newDate) >= getWeekNumber(firstUse)
                ) {
                    setSelectedTime(newDate);
                }
            break;
            default:
            break;
        }
    }

    const nextClicked = () => {
        var newDate;
        switch (selectedTimeUnit) {
            case TimeUnit.YEAR:
                newDate = new Date(selectedTime.getFullYear() + 1, 0, 1);
                if (newDate.getFullYear() <= (new Date()).getFullYear()) {
                    setSelectedTime(newDate);
                }
            break;
            case TimeUnit.MONTH:
                newDate = (new Date(selectedTime.getFullYear(), selectedTime.getMonth() + 1, 1));
                if (
                    newDate.getFullYear() < (new Date()).getFullYear() ||
                    newDate.getMonth() <= (new Date()).getMonth()
                ) {
                    setSelectedTime(newDate);
                }
            break;
            case TimeUnit.WEEK:
                newDate = (new Date(selectedTime.getFullYear(), 0, 1 + (getWeekNumber(selectedTime)) * 7));
                if(
                    newDate.getFullYear() < (new Date()).getFullYear() ||
                    getWeekNumber(newDate) <= getWeekNumber(new Date())
                ) {
                    setSelectedTime(newDate);
                }
            break;
            default:
            break;
        }
    }

    const showNavigateButtons = () => {
        if (!firstUse) {
            return false
        }
        return !(selectedTimeUnit === TimeUnit.YEAR && firstUse.getFullYear() === new Date().getFullYear());
    }

    const getMondayOfWeek = (year, weekNumber) => {
        // Create a date object for January 4th of the given year
        // January 4th always falls in the first ISO week
        const january4 = new Date(year, 0, 4);

        // Find the Monday of the first ISO week
        const firstMonday = new Date(
            january4.getFullYear(),
            january4.getMonth(),
            january4.getDate() - (january4.getDay() || 7) + 1
        );

        // Add the number of weeks minus 1 to get to the desired week
        const mondayOfWeek = new Date(
            firstMonday.getFullYear(),
            firstMonday.getMonth(),
            firstMonday.getDate() + (weekNumber - 1) * 7
        );

        // Ensure the time is set to 12 AM
        mondayOfWeek.setHours(0, 0, 0, 0);

        return mondayOfWeek;
    }

    useEffect(() => {
        setSelectedTimeLabel(calculateSelectedTimeLabel(selectedTime, selectedTimeUnit));

        if(onDateSelected) {
            const selectedYear = selectedTime.getFullYear();
            const selectedMonth = selectedTime.getMonth();

            var from_date;
            var to_date;
            switch (selectedTimeUnit) {
                case "year":
                    from_date = new Date(selectedYear, 0, 1);
                    to_date = new Date(selectedYear + 1, 0, 1);
                    break;
                case "month":
                    from_date = new Date(selectedYear, selectedMonth, 1);
                    to_date = new Date(selectedYear, selectedMonth + 1, 1);
                    break;
                case "week":
                    const weekNumber = getWeekNumber(selectedTime);
                    const monday = getMondayOfWeek(selectedYear, weekNumber);
                    from_date = monday;
                    to_date = new Date(monday.getTime() + (7 * 24 * 60 * 60 * 1000)); // Add 7 days to Monday
                    break;
                default:
                    break;
            }

            if(from_date && to_date) {

                // Set to date to current date if it's in the future
                if (to_date > new Date()) {
                    to_date = new Date();
                }

                const from_unix = from_date.getTime();
                const to_unix = to_date.getTime();

                console.log("activityOverTime- about to call onDateSelected, ", to_date)
                onDateSelected(from_unix, to_unix, selectedTimeUnit);
            }
        }

    }, [selectedTime, selectedTimeUnit])

    useEffect(() => {
        document.addEventListener("click", (event) => {
            if (event.target.closest(".DatePicker") === null) {
                closePicker();
            }
        });

        setSelectedTimeLabel(calculateSelectedTimeLabel(selectedTime, selectedTimeUnit));
    }, [selectedTime, selectedTimeUnit]);

    useEffect(() => {
        if(!expanded) {
            /* 
             * Reset the picker such that it is ready to show
             * the the selected time, once it's opened again.
             */
            showSelectedTime();
        }
    }, [expanded, selectedTime]);
    
    return (
        <div className="DatePicker">
            <div className={getWrapperClass()}>
                <div className="toggle-row">
                    <div 
                        className="prev-button"
                        onClick={prevClicked}
                        style={{display: showNavigateButtons() ? "flex" : "none"}}
                    >
                        <img src={left_arrow} alt="left arrow" />
                    </div>
                    <div 
                        className="toggle"
                        onClick={toggleClicked}
                    >
                        {selectedTimeLabel}
                        <div className="expand-button">
                            <img src={down_arrow} alt="down arrow" />
                        </div>
                    </div>
                    <div 
                        className="next-button"
                        onClick={nextClicked}
                        style={{display: showNavigateButtons() ? "flex" : "none"}}
                    >
                        <img src={right_arrow} alt="right arrow" />
                    </div>
                </div>

                {expanded && ( <div className="content-wrapper">
                    <div className="content">
                        <div className="year-row">
                            <div 
                                className="left-arrow"
                                onClick={prevYearClicked}
                            >
                                <img src={left_arrow} alt="left arrow" />
                            </div>
                            <div 
                                className={getYearClass()}
                                onClick={yearClicked}
                            >
                                <div className="year-text">
                                    {shownYear}
                                </div>
                            </div>
                            <div 
                                className="right-arrow"
                                onClick={nextYearClicked}
                            >
                                <img src={right_arrow} alt="right arrow" />
                            </div>
                        </div>

                        <div className="colomns">
                            {type !== "week" &&
                                <div className="month-tower">
                                    <div 
                                        className={getSwitchClass(TimeUnit.MONTH)}
                                        onClick={monthSwitchClicked}
                                    >
                                        {t("Måned")}
                                    </div>
                                    <div className="month-colomn">
                                        { months.map((month, i) => (
                                            <div
                                                key={i}
                                                className={getMonthClass(month)}
                                                onClick={() => monthButtonClicked(month)}
                                            >
                                                {month}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            <div className={getWeekTowerClass()}>
                                <div 
                                    className={getSwitchClass(TimeUnit.WEEK)}
                                    onClick={weekSwitchClicked}
                                >
                                    {t("Uge")}
                                </div>
                                <div className="week-colomn">
                                    {
                                        weekMatrix().map((row, row_index) => (
                                            <div 
                                                key={row_index}
                                                className="week-row"
                                            >
                                                {row.map((week, col_index) => (
                                                    <div 
                                                        key={col_index}
                                                        className={week === ""
                                                            ? "filler"
                                                            : getWeekClass(week)
                                                        }
                                                        onClick={() => {
                                                            if (week !== "") {
                                                                weekButtonClicked(week)
                                                            }
                                                        }}
                                                    >
                                                        {week}
                                                    </div>
                                                ))}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="actions-row">
                            <div className="left-section">
                                {type === "week" ?
                                    <div 
                                        className="cancel-button"
                                        onClick={cancelClicked}
                                    >
                                        {t("Annuller")}
                                    </div>
                                :
                                <div 
                                    className="ytd-button"
                                    onClick={ytdActionClicked}
                                >
                                    {t("ÅTD")}
                                </div>
                                }
                            </div>
                            <div className="right-section">
                                { type !== "week" &&
                                    <div 
                                        className="cancel-button"
                                        onClick={cancelClicked}
                                    >
                                        {t("Annuller")}
                                    </div>
                                }
                                <div 
                                    className="save-button"
                                    onClick={onSaveClicked}
                                >
                                    {t("Gem")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    );
}

export default DatePicker;
