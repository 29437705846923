import React, { useState, useEffect, useCallback } from "react"
import "./AppHeader.css"
import SecondHeader from "./SecondHeader/SecondHeader.jsx"
import ThirdHeader from "./ThirdHeader/ThirdHeader.jsx"
import logo from "./../../../assets/icons/aris_insights.svg"
import logo_green from "./../../../assets/icons/aris-insights-green.svg"
import location_building from "./../../../assets/icons/location_building.svg";
import overview_grid from "./../../../assets/icons/overview_grid.svg";
import { signOut } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { ref, getDownloadURL } from 'firebase/storage';
import { auth, storage } from './../../../firebase';
import { useUser } from "./../../../contexts/UserContext.js";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AppHeader = ({}) => {
	const { t } = useTranslation();
	const { userData } = useUser();
	const navigate = useNavigate();
	const {unitId, wadeReportMode} = useParams();
	const [logout_dropdown_show, set_logout_dropdown_show] = useState(false)
	const location = useLocation();

	const [isWadeReportMode, setIsWadeReportMode] = useState(false);

	const navigateToHome = useCallback(() => {
		navigate('/')
	}, [navigate]);

	const userButtonClicked = useCallback(() => {
		set_logout_dropdown_show(!logout_dropdown_show)
	}, [logout_dropdown_show])

	useEffect(() => {
		const handlePageClick = (event) => {
			if (!event.target.closest('.user-button')) {
				set_logout_dropdown_show(false);
			}		
		};
	
		document.addEventListener('click', handlePageClick);
	
		return () => {
		  document.removeEventListener('click', handlePageClick);
		};
	  }, []);

	  useEffect(() => {
		/*
        const queryParams = new URLSearchParams(location.search);
		////console.log("wadeReportMode header", wadeReportMode)
        const isWadeReportMode = (queryParams.get('wade_report_mode') === 'true') || wadeReportMode == "month";
        const wadeReportTimeIsMonth = queryParams.get('wade_report_time') == "month" || wadeReportMode == "month";
        setIsWadeReportMode(isWadeReportMode)
        setIsWadeReportTimeMonth(wadeReportTimeIsMonth)
		*/
		location.pathname.includes('wade-report-mode') && setIsWadeReportMode(true)
		////console.log("wadeReportMode header 2", location.pathname)
    }, [location])

	useEffect(() => {
		/*
		////console.log("wadeReportMode header", wadeReportMode)
		setIsWadeReportMode(wadeReportMode)
        setIsWadeReportTimeMonth(wadeReportMode)*/
	}, [wadeReportMode])

	const [logoFileName, setLogoFileName] = useState(null);
	const [logoUrl, setLogoUrl] = useState(null);

	const loadImage = async () => {
        
		if (!logoFileName) {
			return;
		}
        const imgRef = ref(storage, `wade_report/partner_logos/${logoFileName}`);

        try {
            const url = await getDownloadURL(imgRef);
            setLogoUrl(url);
        } catch (error) {
			////console.log("Error loading logo image", error);
        }
    };

	useEffect(() => {
		loadImage();
	}, [logoFileName])

	useEffect(() => {
        const queryParams = new URLSearchParams(location.search);

        queryParams.get("logo-file-name") && setLogoFileName(queryParams.get("logo-file-name"))
        
    }, [location])

	return (
		<div className="AppHeader">
			{ !location.pathname.includes('admin-overview') &&
				<div 
					className="main-wrapper"
					style={{
						"borderBottom": isWadeReportMode ? "none" : "1px solid var(--gray-700)"
					}}
				>
					<div className="flex-wrapper">
						{ isWadeReportMode?
						<img 
							className="logo" 
							src={logo_green} 
							alt="Aris Insights"
							onClick={()=>{navigateToHome()}}
							style={{"cursor": "pointer"}}
						/>
						: <img 
							className="logo" 
							src={logo} 
							alt="Aris Insights"
							onClick={()=>{navigateToHome()}}
							style={{"cursor": "pointer"}}
						/>
						}
					</div>

					{ isWadeReportMode 
					? (
						<div className="wadeReportModeLogo">
							<img 
								src={logoUrl}
								alt="Logo"
							/>
						</div>
					)
					: (
						userData && userData.isAdmin &&
						<div className="toggle">
							<div className="toggle-active"></div>
							<div 
								className="inner-toggle"
								onClick={()=>{navigate('/')}}
							>
								<img className="tiny-icon" src={location_building} alt=""/>
								<div className="toggle-text">
									{t("Lokationer")}
								</div>
							</div>
							<div 
								className="inner-toggle"
								onClick={()=>{navigate('/recent')}}
							>
								<img className="tiny-icon" src={overview_grid} alt=""/>
								<div className="toggle-text">
									{t("Seneste")}
								</div>
							</div>
							<div 
								className="inner-toggle"
								onClick={()=>{navigate('/screens')}}
							>
								<img className="tiny-icon" src={overview_grid} alt=""/>
								<div className="toggle-text">
									Skærme
								</div>
							</div>
							<div 
								className="inner-toggle"
								onClick={()=>{navigate('/admin-overview')}}
							>
								<img className="tiny-icon" src={overview_grid} alt=""/>
								<div className="toggle-text">
									Admin overblik
								</div>
							</div>
						</div>
					)}
					{ !isWadeReportMode && (
						<div className="flex-wrapper">
							<div className="user-button-wrapper">
								<div 
									className="user-button" 
									onClick={ () => {
										userButtonClicked()
									}}
									style={{"backgroundColor": (userData ? userData["color"] : "")}}
								>
									<div className="icon-text">
										{ (userData && userData["name"]) ? userData["name"].slice(0, 2).toUpperCase() : '' }
									</div>
								</div>
								{
									logout_dropdown_show &&
									<button className="aris-button-secondary header-dropdown" onClick={()=>{signOut(auth)}}
										type="submit">
										{t("Log ud")}
									</button>
								}
							</div>
						</div>
					)}
				</div>
				}
				{(location.pathname.includes('locations/') || location.pathname.includes('unit/')) && <SecondHeader unitId={unitId} isWadeReportMode={isWadeReportMode}/>}
				{(!isWadeReportMode && location.pathname.includes('unit/')) && <ThirdHeader />}
			
		</div>
	);
}

export default AppHeader