import {useContext, createContext, useState, useRef, useEffect} from 'react';

import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import {db, storage} from '../firebase';
import { getDownloadURL, ref } from 'firebase/storage';

const LatestImageContext = createContext(null);

export const useLatestImage = () => {
    return useContext(LatestImageContext);
}

export const LatestImageContextProvider = ({children}) => {
    const [latestImageCards, setLatestImageCards] = useState([]);
    const [latestImageCardForUnit, setLatestImageCardForUnit] = useState({});
    const [lastRecordIdForUnit, setLastRecordIdForUnit] = useState({});
    const [imagesToCheck, setImagesToCheck] = useState([]);
    const imageRetryInterval = 1000

    const getLatestRecordData = async (unitId) => {
        try {
            console.log("Firebase call")

            const q = query(
                collection(db, `/units/${unitId}/inference-records`),
                orderBy("timestamp", "desc"),
                limit(1)
            );
            const snapshot = await getDocs(q);
            const records = snapshot.docs.map(doc => ({
                ...doc.data(),
                timestamp: new Date(doc.data().timestamp),
            }));
            const record = records[0];
            
            return record
        } catch (error) {
            console.error("records- Error fetching records: ", error);
        }
    }

    const getLatestImageCard = async (locationUnit) => {
        const unitId = locationUnit.unit;
        const latestImageName = locationUnit.record.img_name;
        const latestImageRefPath = `images/${latestImageName}`;
        const imageRef = ref(storage, latestImageRefPath);
        var url = ""
        try {
            console.log("getting download url for ", latestImageRefPath)
            console.log("Firebase call")
            url = await getDownloadURL(imageRef);
            console.log("Got download url: ", url)
        } catch (error) {
            console.log("Error getting download url for ", latestImageRefPath)
            console.error("Error (getting download url): ", error)
            throw new Error("Error getting download url for ", latestImageRefPath)
        }

        const card = {
            record: {
                ...locationUnit.record,
                timestamp: new Date(locationUnit.record.timestamp)
            },
            location: locationUnit.location,
            unit: unitId,
            url: url
        }

        return card;
    }

    const loadLatestImages = async (locationUnits) => {
        for (let i = 0; i < locationUnits.length; i++) {
            const locationUnit = locationUnits[i];
            const latestImageCard = await getLatestImageCard(locationUnit);

            setLatestImageCards((prev) => [...prev, latestImageCard]);
        }
    }

    const loadLatestImagesForRecords = async (recordDataDictionary) => {
        for (let i = 0; i < Object.values(recordDataDictionary).length; i++) {
            const recordData = Object.values(recordDataDictionary)[i];
            
            var exists = false;
            var same = false
            for (let j = 0; j < imagesToCheck; j++) {
                if (imagesToCheck[j].unit_id === recordData.unit_id) {
                    exists = true;
                }

                if (imagesToCheck[j].img_name === recordData.img_name) {
                    same = true;
                }
            } 

            if (!same) {
                if (exists) {
                    setImagesToCheck((prev) => prev.map((_recordData) => {
                        if (recordData.unit_id === _recordData.unit_id) {
                            return recordData
                        }    
                    }))
                } else {
                    setImagesToCheck((prev) => [...prev, recordData]);
                }
            }
        }
    }

    const onImageRetryTimer = () => {
        for (var i = 0; i < imagesToCheck.length; i++) {
            const recordData = imagesToCheck[i];
            setImagesToCheck((prev) => prev.filter((_recordData) => _recordData !== recordData))

            getLatestImageCard(recordData).then((latestImageCard) => {
                var exists = false;
                console.log("Exists test")
                for (let i = 0; i < latestImageCards.length; i++) {
                    console.log("latestImageCards[i].unit", latestImageCards[i].unit)
                    if (latestImageCards[i].unit === recordData.unit) {
                        console.log("Exists")
                        exists = true

                        if (latestImageCards[i].record.img_name !== recordData.record.img_name) {
                            console.log("Exists but different")

                            const newLatestImageCards = latestImageCards.map((card, j) => {
                                if (j == i) {
                                    return latestImageCard
                                }

                                return card
                            })

                            setLatestImageCards(newLatestImageCards)
                        }
                    }
                }

                if (!exists) {
                    setLatestImageCards((prev) => [...prev, latestImageCard]);
                }
            }).catch((error) => {
                console.error("Error getting image: ", error)
                setImagesToCheck((prev) => [...prev, recordData])
            })
        }
    }

    const useInterval = (callback, interval) => {
        const callbackRef = useRef();

        useEffect(() => {
            callbackRef.current = callback;
        }, [callback])

        useEffect(() => {
            const tick = () => {
                callbackRef.current();
            }

            if (interval !== null) {
                const id = setInterval(tick, interval);
                return () => clearInterval(id);
            }
        }, [interval]);
    }

    useInterval(() => {
        onImageRetryTimer();
    }, imageRetryInterval);

    useEffect(() => {
        // Remove duplicates
        const seen = new Set();
        const filteredLatestImageCards = latestImageCards.filter(el => {
            const duplicate = seen.has(el.unit);
            seen.add(el.unit);
            return !duplicate;
        });
    }, [latestImageCards]);

    return (
        <LatestImageContext.Provider value={{
            latestImageCards,
            latestImageCardForUnit,

            loadLatestImages,
            loadLatestImagesForRecords
        }}>
            {children}
        </LatestImageContext.Provider>
    )
}