import JSZip from "jszip";
import { saveAs } from "file-saver";

import React, { useState, useEffect, useRef } from 'react';
import { useUnitDataCache } from '../../../../contexts/UnitDataCacheContext';
import { useSecondHeader } from './../../../../contexts/SecondHeaderContext';
//import { Dialog } from '@material-ui/core'; // Assuming you're using Material-UI
import DatePickerComponent from '../../../../components/DatePicker/DatePicker';
import './ImagesSubPage.css'; // Import the styles
import { useParams } from 'react-router-dom';
import RecordDialog from './RecordDialog/RecordDialog.jsx';
import { Modal } from '@mui/material';
import { DialogRecordProvider } from './RecordDialog/RecordDialogContext';
import { useTranslation } from 'react-i18next';

import down_arrow from "./../../../../assets/icons/down-arrow.svg"
import { useUnitDataParser } from '../../../../contexts/UnitDataParserContext.js';
import { use } from 'i18next';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useUser } from '../../../../contexts/UserContext';
import { auth, storage } from '../../../../firebase';
import { getDownloadURL, ref } from 'firebase/storage';
import meat_balls from "./../../../../assets/icons/three_dots.svg"
import { getDateLocaleFromLanguage } from "../../../../common/dateTools.js";

const ImagesSubPage = () => {
    const { t } = useTranslation();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const { unitId } = useParams();
    const { records, getRecords, setRecords, getOtherUserInfo } = useUnitDataCache();
    const [ localRecords, setLocalRecords ] = useState([]);
    const [ currentPageRecords, setCurrentPageRecords] = useState([]);
    const { currentUnit, getUnitData } = useSecondHeader();
    const { getColorForCategory, localCustomEvalCategories, setLocalCustomEvalCategories } = useUnitDataParser();
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [activeFilter, setActiveFilter] = useState('');
    const [flipSort, setFlipSort] = useState(false);
    const [flipAccountedRecords, setFlipAccountedRecords] = useState([]);
    const [showPagePaginatorDropdown, setShowPagePaginatorDropdown] = useState(false);
    const [modalInitialIndex, setModalInitialIndex] = useState(0);
    const [isMeatBallExpanded, setIsMeatBallExpanded] = useState(false);

    // MARK: Refs
    const meatBallMenuRef = useRef(null);
    const meatBallButtonRef = useRef(null);
    
    // Defaulto to year to date
    const defaultStartUnix = (new Date(new Date().getFullYear(), 0, 1)).getTime();
    const defaultEndUnix = (new Date()).getTime();

    const [startUnix, setStartUnix] = useState(defaultStartUnix);
    const [end_unix, setEndUnix] = useState(defaultEndUnix);

    const [votedCategoryForRecords, setVotedCategoryForRecords] = useState([]);
    

    const [user] = useAuthState(auth);
    const { userData } = useUser();

    const special_categories = [
        "Kan ikke vurderes", 
        //"Test", "Test", "Test", "Test", "Test", "Test", "Test", "Test", "Test"
    ]
    const specified_colors = {
        [special_categories[0]]: "#BFBFBF"
    }


    const totalPages = () => {
        return Math.ceil(localRecords.length / pageSize)
    }

    useEffect(() => {
        if (localRecords) {
            if (flipSort) {
                setFlipAccountedRecords([...localRecords].reverse())
            } else {
                setFlipAccountedRecords([...localRecords])
            }
        }
    }, [localRecords, flipSort])
    useEffect(() => {
        filterRecords()
    }, [records]);

    useEffect(() => {
        setCurrentPage(0);
        filterRecords()
    }, [activeFilter]);

    useEffect(() => {
        const votedCategories = localRecords.map(getVotingForItem)
        
        setVotedCategoryForRecords(votedCategories)
    }, [localRecords]);

    const getVotingForItem = (record) => {
        if(record.user_evals) {
            const users_voted = Object.keys(record.user_evals)
            let votesForCategory = {}
            var highestVoted;
            var tie = false
            for (let i = 0; i < users_voted.length; i++) {
                const category = record.user_evals[users_voted[i]]
                
                if (category) {
                    const translatedCategory = t(category)
                    if (votesForCategory[translatedCategory]) {
                        votesForCategory[translatedCategory] += 1
                    } else {
                        votesForCategory[translatedCategory] = 1
                    }

                    if(!highestVoted) {
                        highestVoted = translatedCategory
                    } else if (highestVoted != translatedCategory) {
                        const votes = votesForCategory[translatedCategory]

                        if (votes > 0) {
                            /*
                            NOTE: this line was buggy for some reason. 
                            votesForCategory[highestVoted] does not have the correct value
                            and it is not even corresponding to the value printed for the dict.
                            const highestVotes = votesForCategory[highestVoted]
                            */

                            var highestVotes = 0
                            var highestVotedOption = undefined
                            const categoryOptions = localCustomEvalCategories.concat(currentUnit.eval_categories)
                            for (let categoryKey in categoryOptions) {
                                let candidateVotes = 0
                                const categoryOption = categoryOptions[categoryKey]
                                const translatedCategoryOption = t(categoryOption)

                                for (let userEvalKey in record.user_evals) {
                                    let _userEval = record.user_evals[userEvalKey]
                                    let _translatedUserEval = t(_userEval)
                                    if (_translatedUserEval == translatedCategoryOption) {
                                        candidateVotes += 1
                                    }
                                }

                                if (candidateVotes > highestVotes) {
                                    highestVotes = candidateVotes
                                    highestVotedOption = categoryOption
                                    highestVoted = highestVotedOption
                                    tie = false
                                } else if (candidateVotes === highestVotes) {
                                    tie = true
                                }
                            }
                        }
                    }
                }
            }

            if (tie) {
                return "Uafgjort"
            }
            
            if (highestVoted) {
                return highestVoted
            }

        }

        return "Ikke vurderet"
    }

    const filterRecords = () => {
        console.log("Filtering records")
        var data = records.filter((elem) => {
            var output = true;
            if (activeFilter !== "") {
                console.log("Filtering for: " + activeFilter)
                const voting = getVotingForItem(elem)
                var votingOrEval = voting
                if (voting === "Ikke vurderet") {
                    votingOrEval = elem.eval
                }

                const votingEquals = votingOrEval === activeFilter
                const votingEqualsTranslated = votingOrEval === t(activeFilter)

                if (!votingEquals && !votingEqualsTranslated) {
                    output = false;
                    console.log("No match - " + voting + " - " + activeFilter)
                } else {
                    console.log("Match - " + voting + " - " + activeFilter)
                }
            }

            return output;
        });

        setLocalRecords(data);
    }

    const handleIndexChange = (newIndex) => {
        // Add any additional logic you need here when the index changes

        // Refresh records in order to see the newest user evals.

        getRecords(unitId, startUnix, end_unix, setRecordsIgnoringOwnEval);
    };

    const handleSortChange = () => {
        setFlipSort(flipSort === false);
        setCurrentPage(0);
        filterRecords();
    };

    const handleDatePicked = async (start_unix, end_unix) => {
        setStartUnix(start_unix);
        setEndUnix(end_unix);
        await getRecords(unitId, start_unix, end_unix, setRecordsIgnoringOwnEval);
    };

    const handlePaginatorClick = (size) => {
        setPageSize(size);
        setCurrentPage(0);
    };

    const handlePaginatorDropdownClick = () => {
        setShowPagePaginatorDropdown(!showPagePaginatorDropdown);
    };

    const prevPage = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 1);
    };

    const nextPage = () => {
        if (currentPage < totalPages() - 1) setCurrentPage(currentPage + 1);
    };

    const setRecordsIgnoringOwnEval = (records) => {
        // Don't overwrite own evals
        var recordsNewValue = [...records];
        recordsNewValue = recordsNewValue.map((elem) => {
            var newRecord = {...elem};
            if (!newRecord["user_evals"]) {
                newRecord["user_evals"] = {};
            }

            if (localRecords) {
                const record = localRecords.find((record) => record.record_id === elem.record_id);

                if (record && record["user_evals"]) {
                    newRecord["user_evals"][user.uid] = record["user_evals"][user.uid];
                }
            }

            return newRecord;
        })
        setRecords(recordsNewValue);
    }

    const openImageDialog = (record, index) => {
        setModalInitialIndex(index);
        setIsDialogOpen(true);

        // Refresh records in order to see the newest user evals.
        getRecords(unitId, startUnix, end_unix, setRecordsIgnoringOwnEval);
    };

    const closeDialog = () => {
        //setCurrentRecordX(undefined);
        setIsDialogOpen(false);
    };

    const getCellClassName = (index, side) => {
        var className = "cdk-cell";

        const lastInPage = Math.min(pageSize, localRecords.length - currentPage * pageSize);
        if (index === 0) {
            className += " first-row-" + side;
        } else if (index === lastInPage - 1) {
            className += " last-row-" + side;
        } else if (side === "right") {
            className += " right-cell"
        }
        
        return className;
    }

    const getVotedCategoryForRecords = (index) => {
        if (!votedCategoryForRecords[index] || votedCategoryForRecords[index] === "Ikke vurderet") {
            return ""
        }

        return votedCategoryForRecords[index]
    }

    const getFormattedDate = (timestamp) => {
        const formattedDate = new Intl.DateTimeFormat('da-DK', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }).format(new Date(timestamp));
          
          // Reformatting to "YYYY-MM-DD"
          const [day, month, year] = formattedDate.split('.');
          const formattedDateISO = `${year}-${month}-${day}`;

          return formattedDateISO;
    }

    const onUserEvalChange = (recordId, category) => {
        var localRecordsNewValue = [...localRecords];
        const index = localRecords.findIndex((elem) => elem.record_id === recordId);
        const userId = user.uid;

        if(!localRecordsNewValue[index].user_evals) {
            localRecordsNewValue[index].user_evals = {}
        }
        localRecordsNewValue[index].user_evals[userId] = category;
        setLocalRecords(localRecordsNewValue);
    }

    const onNewCategorySubmitted = (recordId, category) => {
        var localRecordsNewValue = [...localRecords];
        const index = localRecords.findIndex((elem) => elem.record_id === recordId);
        const userId = user.uid;
        if(!localRecordsNewValue[index].user_evals) {
            localRecordsNewValue[index].user_evals = {}
        }
        localRecordsNewValue[index].user_evals[userId] = category;
        setLocalRecords(localRecordsNewValue);
    }

    const adjustedIndex = (index) => {
        // Account for reversed order, and current page
        return flipSort ? localRecords.length - 1 - index - currentPage * pageSize : index + currentPage * pageSize;   
    }

    const handleClick = (e) => {
        if (meatBallButtonRef.current && meatBallButtonRef.current.contains(e.target)) {
            setIsMeatBallExpanded(!isMeatBallExpanded);
        } else {
            setIsMeatBallExpanded(false);
        }
    }

    async function downloadAsZip(imageUrls, csvContent) {
        let csvWithoutHeaders = csvContent.slice(1)
        console.log("downloadAsZip called");
        const zip = new JSZip();
        const folder = zip.folder("bulk_images");
      
        console.log("Fetching image blobs...")
        const blobs = await fetchImagesAsBlobs(imageUrls, 10)
        console.log('Fetched blobs:', blobs);

        console.log("Adding blobs to prepared zip folder")
        for (let i = 0; i < blobs.length; i++) {
            console.log("Image #:", i)
            const blob = blobs[i];
            const bulkDownloadCSVDataElement = csvWithoutHeaders[i];
            const fileName = bulkDownloadCSVDataElement[0];
            folder.file(fileName, blob);
        }
        console.log("Added blobs to prepared zip folder")
    
        folder.file("bulk_download.csv", convertToCSV(csvContent));
    
        console.log("Generating zip file...")
        // Generate the ZIP file and download it
        zip.generateAsync({ type: "blob" }).then((content) => {
            console.log("Zip file generated")
            console.log("Downloading zip file...")
            saveAs(content, "images.zip");
            console.log("Downloaded zip file")
        });
      }

      async function fetchImagesAsBlobs(urls, batchSize = 10) {
        const fetchBlob = async (url) => {
            console.log("Fetching result for: ", url);
            const response = await fetch(url);
            console.log("Fetched result for url.");
            if (!response.ok) {
                throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
            }
            console.log("Converting to blob")
            const _blob = await response.blob();
            console.log("Converted to blob")

            return _blob
        };
    
        const results = [];

        console.log("Starting batch fetching")
        console.log("Batch size: ", batchSize)
        for (let i = 0; i < urls.length; i += batchSize) {
            console.log("Batch fetching iteration: ", i);
            const batch = urls.slice(i, i + batchSize);
            
            let blobs = [];

            for(let j = 0; j < batch.length; j++) {
                console.log("Batch fetching iteration inner: ", j);
                console.log("Batch fetching iteration inner url: ", batch[j]);
                const url = batch[j];
                const _blob = await fetchBlob(url)
                blobs.push(_blob);
            }
            
            console.log("Batch fetched")
            results.push(...blobs);
        }
    
        return results;
    }
    

    const bulkDownloadClicked = async () => {
        console.log("Bulk download clicked");
        try {
            // Get the download URLs
            var imageUrls = [];
            var bulkDownloadCSVDataElements = [];
            var csvContent = []

            var headers = ["file_name", "machine_eval"]

            var userCache = {}

            console.log("Starting image url fetching loop");
            for (var i = 0; i < localRecords.length; i++) {
                console.log("Starting image fetching loop iteration: ", i);
                const record = localRecords[i];

                const imgRefURL = `images/${record.img_name}`
                const imgRef = ref(storage, imgRefURL);
                try {
                    console.log("Getting download URL for: ", record.img_name);
                    const imageURL = await getDownloadURL(imgRef);
                    console.log("Got image URL: ", imageURL);
                    imageUrls.push(imageURL);
                    
                    let fileName = imageURL.split("/").pop().split("%2F").pop().split("?")[0];

                    var bulkDownloadCSVDataElement = {
                        file_name: fileName,
                        machine_eval: record.eval
                    }

                    if (record.user_evals) {
                        for (let key in record.user_evals) {
                            let user_eval = record.user_evals[key];
                            let userName;
                            if (key in userCache) {
                                userName = userCache[key];
                            } else {
                                console.log("Getting user name for: ", key);
                                let userResults = await getOtherUserInfo([key]);

                                if (userResults.length > 0) {
                                    let user = userResults[0];
                                    console.log("Got user: ", user);
                                    userName = user.name
                                    userCache[key] = userName;
                                }
                            }

                            if (userName) {
                                bulkDownloadCSVDataElement[userName] = user_eval;

                                // TODO: Guard against people with same usernames, or make sure they can't have that.
                                if (!headers.includes(userName)) {
                                    headers.push(userName);
                                }
                            }
                        }
                    }

                    bulkDownloadCSVDataElements.push(bulkDownloadCSVDataElement);

                    console.log("Image fetching done for record.")
                } catch (error) {
                    console.log("Error getting download URL");
                    console.log("Record: ", record);
                    console.log("imgRefURL: ", imgRefURL);
                    console.log("imgRef: ", imgRef);
                    console.log("Error: ", error);
                }
            }

            csvContent.push(headers)

            for (var i = 0; i < bulkDownloadCSVDataElements.length; i++) {
                var row = []
                for (var j = 0; j < headers.length; j++) {
                    var header = headers[j]
                    var value = bulkDownloadCSVDataElements[i][header] ?? ""
                    row.push(value)
                }
                csvContent.push(row)
            }
        
            // Download the images as a ZIP
            console.log("About to download images as zip");
            await downloadAsZip(imageUrls, csvContent);
        } catch (error) {
            console.error("Error downloading images: ", error);
        }
    }

    const downloadCSVOnly = async () => {
        console.log("CSV-only download clicked");
        try {
            var bulkDownloadCSVDataElements = [];
            var csvContent = []
            var headers = ["file_name", "machine_eval"]
            var userCache = {}

            console.log("Starting CSV data collection");
            for (var i = 0; i < localRecords.length; i++) {
                const record = localRecords[i];
                let fileName = record.img_name;

                var bulkDownloadCSVDataElement = {
                    file_name: fileName,
                    machine_eval: record.eval
                }

                if (record.user_evals) {
                    for (let key in record.user_evals) {
                        let user_eval = record.user_evals[key];
                        let userName;
                        if (key in userCache) {
                            userName = userCache[key];
                        } else {
                            console.log("Getting user name for: ", key);
                            let userResults = await getOtherUserInfo([key]);

                            if (userResults.length > 0) {
                                let user = userResults[0];
                                console.log("Got user: ", user);
                                userName = user.name
                                userCache[key] = userName;
                            }
                        }

                        if (userName) {
                            bulkDownloadCSVDataElement[userName] = user_eval;

                            if (!headers.includes(userName)) {
                                headers.push(userName);
                            }
                        }
                    }
                }

                bulkDownloadCSVDataElements.push(bulkDownloadCSVDataElement);
            }

            csvContent.push(headers)

            for (var i = 0; i < bulkDownloadCSVDataElements.length; i++) {
                var row = []
                for (var j = 0; j < headers.length; j++) {
                    var header = headers[j]
                    var value = bulkDownloadCSVDataElements[i][header] ?? ""
                    row.push(value)
                }
                csvContent.push(row)
            }

            // Create and download the CSV file
            const csvString = convertToCSV(csvContent);
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, "records.csv");
        } catch (error) {
            console.error("Error downloading CSV: ", error);
        }
    }

    function getUserLocale() {
        var userLocale = 'da-DK'
        
        if (userData && userData.language) {
            userLocale = getDateLocaleFromLanguage(userData.language)
        }

        return userLocale
    }

    const convertToCSV = (objArray) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
    
        for (let i = 0; i < array.length; i++) {
          let line = '';
          for (let index in array[i]) {
            if (line !== '') line += ',';
    
            line += array[i][index];
          }
          str += line + '\r\n';
        }
        return str;
      };

    const isCustomCategoryAlreadyIncluded = (category) => {
        var output = false;
        if (currentUnit && currentUnit.eval_categories) {
            for (let i = 0; i < currentUnit.eval_categories.length && !output; i++) {
                const translatedEval = t(currentUnit.eval_categories[i]);
                if (translatedEval === category) {
                    output = true;
                }
            }
        }

        return output;
    }

    // On mount
    useEffect(() => {
        if (!currentUnit) {
            getUnitData(unitId)
        }

        getRecords(unitId, defaultStartUnix, defaultEndUnix, setRecordsIgnoringOwnEval);
    }, []);

    useEffect(() => {
        const currentPageRecordsNewValue = localRecords.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
        setCurrentPageRecords(currentPageRecordsNewValue)
    }, [localRecords, currentPage, pageSize]);

    useEffect(() => {
        if (currentUnit) {
            
            var localCustomEvalCategoriesNewValue = []
            
            // Add special caregories
            for (var i = 0; i < special_categories.length; i++) {
                localCustomEvalCategoriesNewValue.push(t(special_categories[i]))
            }

            // Add custom categories
            if (currentUnit.custom_categories) {
                for (var j = 0; j <  currentUnit.custom_categories.length; j++) {
                    let category = currentUnit.custom_categories[j]
                    if (!isCustomCategoryAlreadyIncluded(category)) {
                        localCustomEvalCategoriesNewValue.push(currentUnit.custom_categories[j])
                    }
                }
            }

            setLocalCustomEvalCategories(localCustomEvalCategoriesNewValue);
        }
    }, [currentUnit]);

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        }
    }, []);

    return (
      <div className="ImagesSubPage">
        <div className="outer-wrapper">
            <div className="main-wrapper">
                <div className="records-tabs">
                    <div className="category-buttons">
                        <button 
                            className="aris-button-secondary" 
                            onClick={() => setActiveFilter('')}
                            style={{
                                color: activeFilter === "" ? "var(--gray-900)" : "#fff",
                                backgroundColor: activeFilter === "" ? "var(--primary-green-500)" : ''
                            }}
                        >
                            {t("Alle")}
                        </button>
                        {currentUnit?.eval_categories?.map((category, i) => (
                            <button
                                key={category}
                                className="aris-button-secondary"
                                onClick={() => setActiveFilter(category)}
                                style={{
                                    color: activeFilter === category ? "var(--gray-900)" : "#fff",
                                    backgroundColor: activeFilter === category ? getColorForCategory(activeFilter) : ''
                                }}
                            >
                                {t(category)}
                            </button>
                        ))}

                        {localCustomEvalCategories.map((category, i) => (
                            <button
                                key={category}
                                className="aris-button-secondary"
                                onClick={() => setActiveFilter(category)}
                                style={{
                                    color: activeFilter === category ? "var(--gray-900)" : "#fff",
                                    backgroundColor: activeFilter === category ? getColorForCategory(activeFilter) : ''
                                }}
                            >
                                {t(category)}
                            </button>
                        ))}
                    </div>

                    <button className="sort-button" onClick={handleSortChange}>
                        <span>{flipSort ? t("Ældste først") : t("Nyeste først")}</span>
                        <div className="date-picker-toggle-arrow sort-arrow">
                            <img
                                src={down_arrow}
                                alt="Sort Arrow"
                                style={{ transform: flipSort ? 'rotate(180deg)' : '' }}
                            />
                        </div>
                    </button>
                    <DatePickerComponent
                        onDateSelected={handleDatePicked}
                    />
                </div>
                <div className="records-table">
                    <table>
                        <thead>
                            <tr className="card-header">
                                <th
                                    style={{
                                        paddingLeft: '4px',
                                    }}
                                >
                                        Dato
                                </th>
                                <th>{t("Dag")}</th>
                                <th>{t("Tid")}</th>
                                <th>{t("Evaluering")}</th>
                                <th>{t("Fraktion")}</th>
                                <th>{t("Brugerevaluering")}</th>
                                <th>
                                    { userData.isAdmin &&
                                        <div className="meat-balls-wrapper">
                                            <div 
                                                className="meat-balls-button-wrapper"
                                                ref={meatBallButtonRef}
                                            >
                                                <div className="meat-balls-button">
                                                    <img src={meat_balls} alt="Options button" />
                                                </div>
                                                <div className="meat-balls-menu-anchor">
                                                    {isMeatBallExpanded && (
                                                        <div className="meat-balls-menu">
                                                            <div 
                                                                className="bulk-download-button"
                                                                onClick={bulkDownloadClicked}
                                                            >
                                                                Download all images
                                                            </div>
                                                            <div 
                                                                className="bulk-download-button"
                                                                onClick={downloadCSVOnly}
                                                            >
                                                                Download CSV only
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {flipAccountedRecords
                                .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                                .map((element, index) => (
                                    <tr key={element.timestamp}>
                                        <td className={getCellClassName(index, "left")}>
                                            {getFormattedDate(element.timestamp)}
                                        </td>
                                        <td className="cdk-cell" style={{ textTransform: 'capitalize' }}>
                                            {new Intl.DateTimeFormat(getUserLocale(), { weekday: 'short' }).format(new Date(element.timestamp))}
                                        </td>
                                        <td 
                                            className="cdk-cell"
                                        >
                                            { new Date(element.timestamp)
                                                .toLocaleTimeString(
                                                    getUserLocale()
                                                )
                                                .replace(/\./g, ':')
                                            }
                                        </td>
                                        <td className="cdk-cell"
                                            style={{
                                                color: getColorForCategory(element.eval)
                                            }}
                                        >
                                            {t(`${element.eval}`)}
                                        </td>
                                        <td className="cdk-cell"
                                            style={{
                                                color: getColorForCategory(element.broad_class_name ?? element.eval)
                                            }}
                                        >
                                            {t(`${element.broad_class_name ?? element.eval}`)}
                                        </td>
                                        <td className="cdk-cell"
                                            style={{
                                                color: getColorForCategory(getVotedCategoryForRecords(adjustedIndex(index)))
                                            }}
                                        >
                                            {t(getVotedCategoryForRecords(adjustedIndex(index)))}
                                        </td>
                                        <td className={getCellClassName(index, "right")}>
                                            <button
                                                className="aris-button-primary"
                                                style={{ fontSize: '14px', lineHeight: '14px', padding: '9px 12px' }}
                                                onClick={() => openImageDialog(element, adjustedIndex(index))}
                                            >
                                                {t("Se billede")}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    <div className="table-footer">
                        {localRecords.length > 0 && (
                            <div className="page-paginator-dropdown-wrapper" onClick={handlePaginatorDropdownClick}>
                                <div className="page-paginator-text">Pr. side: {pageSize}</div>
                                <div className="page-paginator-dropdown-toggle">
                                    <img
                                        className={`tiny-icon ${showPagePaginatorDropdown ? 'page-paginator-dropdown-toggle-on' : ''}`}
                                        src={down_arrow}
                                        alt="."
                                    />
                                </div>
                                {showPagePaginatorDropdown && (
                                    <div className="page-paginator-dropdown">
                                        {[10, 20, 50, 100].map((size) => (
                                            <div key={size} className="page-paginator-dropdown-elem" onClick={() => handlePaginatorClick(size)}>
                                                {size}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="page-paginator-navigator">
                            <div
                                className={`page-paginator-navigator-elem ${currentPage === 0 ? 'page-paginator-navigator-elem-disabled' : ''}`}
                                onClick={prevPage}
                            >
                                <img className="page-paginator-left-arrow" src={down_arrow} alt="." />
                            </div>
                            <div className="page-paginator-navigator-current">
                                {currentPage * pageSize} -{' '}
                                {currentPage === totalPages() - 1 ? localRecords.length : (currentPage + 1) * pageSize} / {localRecords.length}
                            </div>
                            <div
                                className={`page-paginator-navigator-elem ${currentPage === totalPages() - 1 ? 'page-paginator-navigator-elem-disabled' : ''}`}
                                onClick={nextPage}
                            >
                                <img className="page-paginator-right-arrow" src={down_arrow} alt="." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DialogRecordProvider 
            onIndexChange={handleIndexChange}
        >
            <Modal
                open={isDialogOpen}
                onClose={closeDialog}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <>
                    <RecordDialog
                        isOpen={isDialogOpen}
                        initialIndex={modalInitialIndex}
                        onUserEvalChange={onUserEvalChange}
                        onNewCategorySubmitted={onNewCategorySubmitted}
                        localRecords={localRecords}
                        localCustomEvalCategories={localCustomEvalCategories}
                        specified_colors={specified_colors}
                    />
                </>
            </Modal>
        </DialogRecordProvider>
      </div>
    );
};

export default ImagesSubPage;
