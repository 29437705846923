
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  da: {
    translation: {
      "før_mid_sentence": "",
      "før_end_of_sentence": "før",

      "YTD": "ÅTD",

      "UNDEFINED": "Uklart",
      "NORMAL": "Normal",
      "IMPREGNATED": "Imprægneret",
      
      "normal_wood_painted": "Normalt malet",
      "normal_wood": "Normalt",
      "impregnated_wood": "Imprægneret",
      "impregnated_wood_painted": "Imprægneret malet",
      "other": "Andet",
      "background": "Baggrund",

      "SOFT_PLASTIC": "Bl\u00f8d plast",
      "HARD_PLASTIC": "Hård plast",

      "hard_pvc": "Hård PVC",
      "hard_plastic": "Hård plast",
      "normal_wood": "Normal",
      "pvc_plastic": "PVC plast",
      "soft_plastic": "Blød plast",
      "bathing_equipment": "Badeudstyr",
      "foam": "Skum",
      "garden_hoses": "Haveslanger",
      "tarp_lattice": "Presenning gitter",

      "filled_plastic_bags": "Fyldte poser",
      "hands": "Hænder",
      "for_burying": "Til nedgravning",
      "residues_after_separation": "Rest efter sortering",

      "undefined": "Andet",

      "Sat": "L\u00f8r",
      "Sun": "S\u00f8n",

      "tree1": {
          "UNDEFINED": "Andet",
          "NORMAL": "Normal",
          "IMPREGNATED": "Imprægneret"
      },
      "plastic1": {
          "UNDEFINED": "Andet",
          "SOFT_PLASTIC": "Blød plast",
          "HARD_PLASTIC": "Hård plast"
      },
      "test": {
          "an-eval": "Imprægneret",
          "another-eval": "Eval 2"
      },

      "DASHBOARD": {
          "ACTIVATIONS_?": "Om den aktuella perioden väljs kommer föregående period att ställas in på samma längd.",
      }
    }
  },
  se: {
    translation: {
      "Velkommen": "Välkommen",
      "Adgangskode": "Lösenord",
      "Glemt kodeord?" : "Glömt lösenord?",
      "Log ud": "Logga ut",

      "Lokationer": "Platser",
      "Seneste": "Senaste",
      "Enheder": "Enheter",

      "Aktiveringer": "Användningar",
      "Aktiveringer i dag": "Användningar idag",
      "Kategorifordeling": "Kategorifördelning",
      
      "normal_wood": "Obehandlet trä",
      "impregnated_wood": "Impregnerat trä",
      "UNDEFINED": "Okänt",
      
      "normal_wood_painted": "Obehandlet målat trä",
      "normal_wood": "Obehandlet trä",
      "impregnated_wood": "Impregnerat trä",
      "impregnated_wood_painted": "Impregnerat målat trä",
      "other": "Annat",
      "background": "Bakgrund",
      "hands": "Händer",

      "Ugentlig aktivitet": "Veckans aktivitet",
      "Aktiveringer over tid": "Användningar över tid",

      "Billeder": "Bilder",
      "Billede": "Bild",
      "Dato": "Datum",
      "Brugervurdering": "Användarvärdering",
      "Se billede": "Se bild",

      "Billed": "Bild",
      "Evaluering": "Bedömning",
      "Brugervurdering": "Använderbedömning",
      "Brugerevaluering": "Använderbedömning",
      "Brugervurderinger": "Användarvärderingar",
      "Kan ikke vurderes": "Kan ej bedömas",
      "Ikke vurderet": "Ej bedömd",
      "Lysstyrke": "Ljusstyrka",
      "Udpeg objekt": "Peka ut objekt",

      "Seneste anvendelse": "Senaste användning",
      "Adresse": "Adress",

      "Man": "Mån",
      "Sat": "Lör",
      "Sun": "Sön",

      "Måned": "Månad",
      "Uge": "Vecka",
      "uge": "vecka",
      "ÅTD": "I år",
      
      "Annuller": "Avbryt",
      "Gem": "Spara",
      "Gennemsnit": "Snitt",
      "Gns": "Snitt",
      "mere": "mer",
      "sammenlignet med året før": "jämfört med föregående år",
      "Ingen ændring": "Ingen förändring",
      "sammenlignet med": "jämfört med",
      "før_mid_sentence": " föregående ",
      "før_end_of_sentence": "",
      "Ingen data": "Inga data",
      "DASHBOARD.ACTIVATIONS_?": "Om den aktuella perioden väljs kommer föregående period att ställas in på samma längd.",
      "ugen": "vecka",
      "måneden": "månad",
      "året": "år",

      "Nyeste først": "Nyast först",
      "Ældste først": "Äldst först",

      "Billede kunne ikke indlæses": "Bilden kunde inte laddas"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "da", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;