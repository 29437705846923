import ReactECharts from 'echarts-for-react'; // ECharts wrapper for React
import React, { useEffect, useState, useCallback } from 'react';
import { Tooltip } from 'react-tooltip';
import { useSecondHeader } from './../../../../contexts/SecondHeaderContext';
import { useUnitDataCache } from './../../../../contexts/UnitDataCacheContext';
import './DashBoardSubPage.css'; // Import the corresponding CSS
import questionCircle from "./../../../../assets/icons/question-circle.svg"
import { useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import DatePicker from "./../../../../components/DatePicker/DatePicker.jsx"
import increase_icon from "./../../../../assets/icons/increase_icon.svg"
import decrease_icon from "./../../../../assets/icons/decrease_icon.svg"
import { useUnitDataParser } from '../../../../contexts/UnitDataParserContext.js';
import ActivationsOverTimeChart from '../../../../components/ActivationsOverTimeChart/ActivationsOverTimeChart.jsx';

const DashBoardSubPage = () => {
    const getMondayOfCurrentWeek = () => {
        const today = new Date();
        const dayOfWeek = today.getDay(); // 0 (Sun) to 6 (Sat)
        const diff = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek; // Calculate the difference to Monday
        const monday = new Date(today.setDate(today.getDate() + diff));
        monday.setHours(0, 0, 0, 0); // Set time to the start of the day
        return monday;
    }

    const { t } = useTranslation();
    const { unitId } = useParams();
    const [colors] = useState([
        '#74D5F2',
        '#BD92F4',
        '#81F3D5',
        '#FF8989',
        '#81ECF3',
        '#53C0FF',
        '#ED9BD2',
        '#FF89B0',
        '#90AAFF',
        '#81F3AD'
    ]);

    const year_start_unix = new Date(new Date().getFullYear(), 0, 1).getTime();
    const now_unix = new Date().getTime();
    const activationsFromDefault = year_start_unix;
    const activationsToDefault = now_unix;
    const activationsPrevFromDefault = new Date(new Date().getFullYear() - 1, 0, 1).getTime();
    const activationsPrevToDefault = new Date(new Date().getFullYear() - 1, 11, 31).getTime();
    const [activationsFrom, setActivationsFrom] = useState(activationsFromDefault);
    const [activationsTo, setActivationsTo] = useState(activationsToDefault);
    const [activationsPrevFrom, setActivationsPrevFrom] = useState(activationsPrevFromDefault);
    const [activationsPrevTo, setActivationsPrevTo] = useState(activationsPrevToDefault);

    // Default heatmap to show the current week
    const activityHeatmapFromDefault = getMondayOfCurrentWeek().getTime();
    const activityHeatmapToDefault = now_unix;
    const [activityHeatmapFrom, setActivityHeatmapFrom] = useState(activityHeatmapFromDefault);
    const [activityHeatmapTo, setActivityHeatmapTo] = useState(activityHeatmapToDefault);
    const [activityHeatmapUnit] = useState("uge");

    const activityOverTimeFromDefault = year_start_unix;
    const activityOverTimeToDefault = now_unix;
    const activityOverTimeUnitDefault = "year";
    const [activityOverTimeFrom, setActivityOverTimeFrom] = useState(activityOverTimeFromDefault);
    const [activityOverTimeTo, setActivityOverTimeTo] = useState(activityOverTimeToDefault);
    const [activityOverTimeUnit, setActivityOverTimeUnit] = useState(activityOverTimeUnitDefault);

    const [timeUnit] = useState('år'); // Default to 'år' (year)

    const [activationsUnit, setActivationsUnit] = useState('year');

    const {
        activationsTotal,
        activationsRelativePercentage,
        getActivationsData,
        getActivityOverTimeData,
        getActivityHeatmapData,

        loadCategoryGroupedRecords,
        loadHeatmapRecords,
        loadBarchartRecords
    } = useUnitDataCache(); 

    const { 
        activationsData, 
        heatmapData, 
        barchartData, 
        setBarChartTimeUnit, 
        setBarChartFrom,
        getColorForCategory
    } = useUnitDataParser();

    const { currentUnit, getUnitData } = useSecondHeader();

    const daysOfWeek = ['Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Sat', 'Sun'];
    
    const [barchartColors, setBarchartColors] = useState([])

    useEffect(() => {
        setBarchartColors(barchartData.map((category) => {return getColorForCategory(category.category)}))
    }, [barchartData])

    const fetchHeatmapData = async () => {
        ////console.log("heatMap- fetchHeatmapData");
        ////console.log("heatMap- Current unit:", currentUnit);
        ////console.log("heatMap- From:", activityHeatmapFrom);
        if (!currentUnit) return;

        try {
            await getActivityHeatmapData(unitId, activityHeatmapFrom, activityHeatmapTo);
        } catch (error) {
            console.error('Error fetching activity heatmap data:', error);
        }

        loadHeatmapRecords(unitId, activityHeatmapFrom, activityHeatmapTo);
    }

    const activityDateSelected = async (start_unix, end_unix) => {
        console.log("activityDateSelected");
        console.log("start_unix", start_unix);
        console.log("end_unix", end_unix);
        setActivityHeatmapFrom(start_unix);
        setActivityHeatmapTo(end_unix);
    }


    const getShade = (baseColor, count, maxCount) => {
        if (count === 0) return 'white';
        const lightenFactor = (1 - count / maxCount) * 1;
        return lightenColor(baseColor, lightenFactor);
    };

    // Function to lighten a color by a given factor
    const lightenColor = (color, factor) => {
        const colorObj = hexToRgb(color);
        const r = Math.round(colorObj.r + (255 - colorObj.r) * factor);
        const g = Math.round(colorObj.g + (255 - colorObj.g) * factor);
        const b = Math.round(colorObj.b + (255 - colorObj.b) * factor);
        return `rgb(${r},${g},${b})`;
    };

    // Convert hex color to RGB
    const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        return {
            r: (bigint >> 16) & 255,
            g: (bigint >> 8) & 255,
            b: bigint & 255,
        };
    };

    const fetchActivityData = async () => {
        ////console.log("activations- Fetching activity data...");
        ////console.log("activations- Current unit:", currentUnit);
        await getActivationsData(unitId, activationsFrom, activationsTo, activationsPrevFrom, activationsPrevTo);
        await loadCategoryGroupedRecords(unitId, activationsFrom, activationsTo)
    }

    const fetchActivityOverTimeData = async () => {
        ////console.log("activityOverTime- Fetching activity over time data...");
        ////console.log("activityOverTime- Current unit:", currentUnit);
        ////console.log("activityOverTime- From:", activityOverTimeFrom);
        ////console.log("activityOverTime- To:", activityOverTimeTo);
        if (!currentUnit || (activityOverTimeFrom === undefined) || (activityOverTimeTo === undefined) || activityOverTimeUnit === undefined) return;
        try {
            await getActivityOverTimeData(unitId, activityOverTimeFrom, activityOverTimeTo, activityOverTimeUnit);
        } catch (error) {
            console.error('activityOverTime-- Error fetching activity over time data:', error);
        }

        loadBarchartRecords(unitId, activityOverTimeFrom, activityOverTimeTo, activityOverTimeUnit)
    }

    // On mount
    useEffect(() => {
        ////console.log("Fetching data... (on mount)");

        if (!currentUnit) {
            ////console.log("Fetching unit data...", unitId);
            ////console.log("Unit id:")
            ////console.log(unitId)
            getUnitData(unitId)
        }

    }, []);

    useEffect(() => {
        fetchActivityData();

        const intervalId = setInterval(async () => {
            fetchActivityData();
        }, 10000);

        return () => {
            intervalId && clearInterval(intervalId);
        }
    }, [currentUnit, activationsFrom, activationsTo, activationsPrevFrom, activationsPrevTo]);
    
    useEffect(() => {
        fetchHeatmapData();

        const heatMapInterval = setInterval(async () => {
            fetchHeatmapData();
        }, 10000);

        return () => {
            heatMapInterval && clearInterval(heatMapInterval);
        }
    }, [currentUnit, activityHeatmapFrom, activityHeatmapTo]);

    useEffect(() => {
        ////console.log("activityOverTime- useEffect, time unit", activityOverTimeUnit)
        fetchActivityOverTimeData();

        const intervalId = setInterval(async () => {
            fetchActivityOverTimeData();
        }, 10000);

        return () => {
            intervalId && clearInterval(intervalId);
        }
    }, [currentUnit, activityOverTimeFrom, activityOverTimeTo, activityOverTimeUnit]);


    useEffect(() => {
        /*
        switch (activityOverTimeUnit) {
            case "week":
                setBarChartOption({...barChartOption,
                    xAxis: {
                        data: ['Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør', 'Søn'],
                        axisTick: {
                            alignWithLabel: false
                        },
                        splitLine: {
                            show: false,
                        }
                    }
                });
                break
            case "month":
                // stops = this.calculateMonthNumbers(this.data_cache.activity_over_time_context()!.start_unix)
                setBarChartOption({...barChartOption,
                    xAxis: {
                        data: Array.from(
                            { length: new Date(activityOverTimeFrom).getDate() }, 
                            (value, index) => (index + 1).toString()
                        ),
                        axisTick: {
                            alignWithLabel: true
                        },
                        splitLine: {
                            show: true,
                            interval: (index) => {
                                const date = new Date(activityOverTimeFrom);
                                const dayOfMonth = index + 1;
                                const testDate = new Date(date.getFullYear(), date.getMonth(), dayOfMonth);
                                if (index === 0 || index === new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() - 1) {
                                    return false;
                                }
                                return testDate.getDay() === 0; // Sunday
                            },
                            lineStyle: {
                                color: '#575757',
                                type: 'solid',
                                cap: 'round',
                            }
                        }
                    },
                });
                break
            case "year":
                setBarChartOption({...barChartOption,
                    xAxis: {
                        data: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
                        axisTick: {
                            alignWithLabel: false
                        },
                        splitLine: {
                            show: false,
                        }
                    }
                });
                break
        }
                */
    }, [activityOverTimeUnit]);

    /*
    const addBarBorderRadius = (_barChartData) => {
        if (_barChartData.length === 0 || _barChartData[0].data == undefined) return;

        for (let dataIndex = 0, moreValue = true; moreValue; dataIndex++) {
            moreValue = false;
            for (let i = _barChartData.length - 1; i >= 0; i--) {
                const data = _barChartData[i].data;
                if (dataIndex < data.length) {
                    moreValue = true;
                    if (data[dataIndex].value !== 0) {
                        data[dataIndex] = {
                            value: data[dataIndex].value,
                            itemStyle: {
                                borderRadius: [50, 50, 0, 0],
                            },
                        };
                        break;
                    }
                }
            }
        }
        
    };*/

    const activationsDateSelected = async (start_unix, end_unix, time_unit) => {

        setActivationsFrom(start_unix);
        setActivationsTo(end_unix);

        const previousPeriodStart = new Date(start_unix);
        previousPeriodStart.setFullYear(previousPeriodStart.getFullYear() - (time_unit === "year" ? 1 : 0));
        previousPeriodStart.setMonth(previousPeriodStart.getMonth() - (time_unit === "month" ? 1 : 0));
        previousPeriodStart.setDate(previousPeriodStart.getDate() - (time_unit === "week" ? 7 : 0));

        // Calculate the end of the previous period so it is has the same length as the current period
        const prevEnd = new Date(end_unix);
        prevEnd.setFullYear(prevEnd.getFullYear() - (time_unit === "year" ? 1 : 0));
        prevEnd.setMonth(prevEnd.getMonth() - (time_unit === "month" ? 1 : 0));
        ////console.log("prev- prev end 1", prevEnd)
        prevEnd.setDate(prevEnd.getDate() - (time_unit === "week" ? 7 : 0));

        setActivationsPrevFrom(previousPeriodStart.getTime());
        ////console.log("prev- prev end", prevEnd)
        setActivationsPrevTo(prevEnd.getTime())

        setActivationsUnit(time_unit);
    };

    const createDonutOption = () => {
        const donutChartData = activationsData.map((category, index) => {
            const color = category.category.includes('UNDEFINED') ? '#A5A5A5' : colors[index];
            return {
                value: category.count,
                name: category.category,
                itemStyle: { color }
            };
        });
    
        return {
            color: colors,
            series: [
                {
                    name: 'Categories',
                    type: 'pie',
                    radius: ['35%', '74%'],
                    avoidLabelOverlap: true,
                    itemStyle: {
                        borderRadius: 6,
                        borderJoin: 'round',
                        borderWidth: 2,
                        borderColor: '#1c1c1c'
                    },
                    emphasis: {
                        disabled: true
                    },
                    cursor: 'default',
                    label: {
                        show: true,
                        position: 'inside',
                        fontFamily: 'Montserrat',
                        fontSize: 12,
                        formatter: (data) => data.value === 0 ? '' : `${((data.value / activationsData.reduce((acc, curr) => acc + curr.count, 0)) * 100).toFixed(1)}%`,
                    },
                    labelLine: {
                        show: false
                    },
                    data: donutChartData,
                    center: ['50%', '40%'],
                }
            ]
        };
    };
    
     // Calculate totals for each day across all categories
     const activityHeatmapTotals = heatmapData.length > 0
     ? heatmapData[0].counts.map((_, colIndex) =>
        heatmapData.reduce((total, category) => total + category.counts[colIndex], 0)
     )
     : [];

    // Function to calculate the average count for each day
    const getAvgCountsHeatmap = (counts) => {
        if (activityHeatmapUnit === 'uge') {
            
            // Count whole week, unless week has not ended yet
            const today = new Date();
            const dayOfWeek = today.getDay(); // 0 (Sun) to 6 (Sat)
            const mondayIndexed = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // 0 (Mon) to 6 (Sun)
    
            // Get the start of the current week (Sunday)
            const startOfWeek = new Date(today);
            startOfWeek.setDate(today.getDate() - mondayIndexed);
            // Set time to the start of the day
            startOfWeek.setHours(0, 0, 0, 0);

            // Check if the given date is in the current week
            var weekBound = 7
            const heatMapDate = new Date(activityHeatmapFrom)
            if (heatMapDate >= startOfWeek && heatMapDate <= today) {
                weekBound = mondayIndexed + 1; // Days passed in the current week, including today
            } 

            const validCounts = counts.slice(0, 7); // Assuming 'uge' means we only care about the first 7 days

            const sum = validCounts.reduce((acc, count) => acc + count, 0);
            if (validCounts.length === 0) {
                return 0;
            }
            return Math.round(sum / weekBound);
        }
        return 0;
    };


    const getCategoryLabel = useCallback((category) => {
        return t(category);
    }, [t]);
    
    const sumArray = (array) => {
        return array.reduce((acc, curr) => acc + curr, 0);
    }

    const visualizeActivityOverTimePercentage = (count) => {
        const totalCounts = sumArray(barchartData.flatMap((category) => category.counts));
        return totalCounts > 0 ? parseFloat(((count / totalCounts) * 100).toFixed(1)) : 0;
    };

    const barDateSelected = (start_unix, end_unix, time_unit) => {
        setActivityOverTimeFrom(start_unix);
        setBarChartFrom(start_unix);
        setActivityOverTimeTo(end_unix);
        ////console.log("activityOverTime- setting time unit", time_unit)
        setActivityOverTimeUnit(time_unit);
        setBarChartTimeUnit(time_unit);
    }

    const getBarChartMargin = () => {
        if (activityOverTimeUnit === 'week') {
            return 20;
        }
        
        return 7;
    }

    return (
        <div className="DashBoardSubPage">
            <div className="wrapper">
                <div className="grid-wrapper">
                    <div 
                        className="card-container activations-card"
                    >
                        <div className="card-header">
                            <div className="card-header-title">
                                {t("Aktiveringer")}
                            </div>
                            <DatePicker 
                                horizontalAnchor="right"
                                onDateSelected={activationsDateSelected}
                            />
                        </div>
                        <div style={{display: "flex", flex: 1, flexDirection: "column", height: "100%"}}>
                            <div className="card-body top-bar" style={{fontSize: 32, position: "relative"}}>
                                <p style={{margin: 0}}>
                                    {
                                        activationsTotal()
                                    }
                                </p>
                                <img 
                                    className="question-icon" 

                                    data-tooltip-id="tooltip1"
                                    data-tooltip-content={t("DASHBOARD.ACTIVATIONS_?")}
                                    data-tooltip-place="top"

                                    style={{top: 8, right: 8}}
                                    src={questionCircle} alt="?" 
                                />
                                <Tooltip 
                                    id="tooltip1" 
                                    place="top" 
                                    effect="solid"
                                    className="aris-tooltip questionMarkTooltip"
                                />
                                <div className="units-count-text ">
                                    <p className="units-count-label" style={{margin: 0}}>
                                        { isFinite(activationsRelativePercentage) &&
                                        <span>
                                        {(activationsRelativePercentage === 0 ?
                                            <span>{t("Ingen ændring") + " "}</span>
                                        :
                                            <span>
                                                {activationsRelativePercentage > 0 
                                                ? 
                                                <span className="trend_icon">
                                                    <img 
                                                        src={increase_icon}
                                                        alt="increase_icon"
                                                    />
                                                </span>
                                                : 
                                                <span className="trend_icon">
                                                    <img 
                                                        src={decrease_icon}
                                                        alt="decrease_icon"
                                                    />
                                                </span>
                                                }
                                                {activationsRelativePercentage > 0 
                                                ? <span className="trend-label-increasing">
                                                    {Math.abs(activationsRelativePercentage)}% {t("mere") + " "} 
                                                </span>
                                                : <span className="trend-label-decreasing">
                                                    {Math.abs(activationsRelativePercentage)}% {t("mindre") + " "} 
                                                </span>
                                                }
                                            </span>
                                        )}
                                         {t("sammenlignet med")}
                                        </span>
                                        }

                                        { !isFinite(activationsRelativePercentage) &&
                                            <span>{t("Ingen data")}</span>
                                        }
                                        {t("før_mid_sentence")}
                                        {activationsUnit == "week" ? " " + t("ugen") + " ": 
                                        activationsUnit == "month" ? " " + t("måneden") + " " 
                                        : " " + t("året") + " "}
                                        {t("før_end_of_sentence")}
                                    </p>
                                </div>
                            </div>

                            <div className="card-body bottom-bar" style={{justifyContent: "start"}}>
                                <div className="data-cards-wrapper">

                                    {
                                        activationsData.map((category, index) => (
                                            <div 
                                                key={index}
                                                className="data-card-large units-count-text bordered" 
                                                style={{borderLeft: category.category === "UNDEFINED" ? '2px solid var(--gray-400)' : '2px solid ' + colors[index % colors.length]}}
                                            >
                                                <div className="units-count-label">{getCategoryLabel(category.category)}</div>
                                                <div className="data-card-count">{category.count}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-container donut-card">
                        <div className="card-header">
                            <div className="card-header-title">{t("Kategorifordeling")}</div>
                        </div>
                        <div style={{ overflow: "hidden", display: 'flex', flex: 1, flexDirection: 'row', width: '100%' }}>
                            <div className="doughnut-section">
                                    <div className="donut-section-content">
                                        <ReactECharts
                                            option={createDonutOption()}
                                            style={{ 
                                                width: '100%', 
                                                minHeight: 280,
                                                justifyContent: 'center',
                                                position: 'relative',
                                                top: 0,
                                                overflow: 'hidden'
                                            }}
                                        />
                                    </div>
                            <div/>
                            </div>
                            <div className="card-body right-bar" style={{ width: '45%', overflowY: 'auto' }}>
                                <div className="right-bar-categories">
                                    {activationsData.map((category, index) => (
                                        <div key={index}>
                                            <div
                                                key={index}
                                                className="data-card-small units-count-text bordered"
                                                style={{
                                                    borderLeft: 
                                                        category.category === "UNDEFINED"
                                                        ? '2px solid var(--gray-400)' 
                                                        : '2px solid ' + colors[index % colors.length]
                                                }}
                                            >
                                                
                                                <div className="units-count-label">{getCategoryLabel(category.category)}</div>
                                                <div className="data-card-count-percentage">
                                                   
                                                    <span>{((category.count / activationsData.reduce((acc, curr) => acc + curr.count, 0)) * 100).toFixed(1)}%</span>
                                                    <span>|</span>
                                                    <span>{category.count}</span>
                                                    
                                                </div>
                                                
                                            </div>
                                            {(index === activationsData.length - 1
                                                ? <></>
                                                : <span style={{width: "100%", minHeight: 2, backgroundColor: "var(--gray-700)"}}></span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-container activity-card">
                        <div className="card-header">
                            <div className="card-header-title">
                                {t("Ugentlig aktivitet")}
                            </div>
                            <div className="card-header-actions">
                                <DatePicker 
                                    type="week"
                                    onDateSelected={activityDateSelected}
                                    defaultTime={new Date(activityHeatmapFromDefault)}
                                />
                            </div>
                        </div>
                        <div className="card-container activity-card">
                            <div className="card-body activity-wrapper" style={{ height: '100%' }}>
                                
                                <div className="activity-headers">
                                    <div className="activity-header"></div>
                                    {daysOfWeek.map((day, index) => (
                                        <div key={index} className="activity-header">{t(day)}</div>
                                    ))}
                                    {activityHeatmapUnit === 'uge' && (
                                        <div className="activity-header" style={{ color: 'var(--gray-500)' }}>{t("Gns")}</div>
                                    )}
                                </div>
                                
                                <div className="activity-averages">
                                    <div className="activity-cell"></div>
                                    
                                    {activityHeatmapTotals.map((total, index) => (
                                        <div
                                            key={index}
                                            className="activity-cell activity-cell-data"
                                            style={{ backgroundColor: total === 0 ? 'white' : "var(--success-400)" }} // Default color for totals
                                        >
                                            { total }
                                        </div>
                                    ))}
                                        
                                    {activityHeatmapUnit === 'uge' && (
                                        <div
                                            className="activity-cell activity-cell-data"
                                            style={{ backgroundColor: 'var(--success-400)' }} // Gray color for average
                                        >
                                            {getAvgCountsHeatmap(activityHeatmapTotals)}
                                        </div>
                                    )}
                                        
                                </div>
                                 
                                {heatmapData.map((category, rowIndex) => {
                                    const maxCount = Math.max(...category.counts);
                                    const baseColor = category.category === "UNDEFINED" ? '#A5A5A5' : colors[rowIndex % colors.length];

                                    return (
                                        <div key={rowIndex} className="activity-categories">
                                            <div className="activity-cell">{getCategoryLabel(category.category)}</div>
                                            {category.counts.map((dayCount, colIndex) => (
                                                <div
                                                    key={colIndex}
                                                    className="activity-cell activity-cell-data"
                                                    style={{ backgroundColor: getShade(baseColor, dayCount, maxCount) }}
                                                >
                                                    {dayCount}
                                                </div>
                                            ))}
                                            {activityHeatmapUnit === 'uge' && (
                                                <div
                                                    className="activity-cell activity-cell-data"
                                                    style={{ backgroundColor: baseColor }}
                                                >
                                                    {getAvgCountsHeatmap(category.counts)}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                                   
                            </div>
                        </div>
                    </div>

                    <div className="card-container bar-card">
                        <div className="card-header">
                            <div className="card-header-title">
                                {t("Aktiveringer over tid")}
                            </div>
                            <div className="card-header-actions">
                                <DatePicker
                                    onDateSelected={barDateSelected}
                                    defaultTime={new Date(activityOverTimeFromDefault)}
                                    defaultUnit={activityOverTimeUnitDefault}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', width: '100%', overflow: 'hidden' }}>
                            <div className="card-body left-bar" style={{ width: '72%' }}>
                                {   /*
                                    <ReactECharts
                                        option={barChartOption2}
                                        style={{ width: '100%', height: 300 }}
                                    />*/
                                }
                                { barchartData && barchartData.length > 0 &&
                                    <ActivationsOverTimeChart 
                                        activityOverTimeData={barchartData} 
                                        startDate={activityOverTimeFrom}
                                        colors={barchartColors}
                                        timeUnit={activityOverTimeUnit}
                                        chartHeight={220}
                                        barMargin={getBarChartMargin(activityOverTimeUnit)}
                                    />
                                }
                            </div>
                            <div className="card-body right-bar" style={{ width: '28%' }}>
                                <div className="right-bar-categories">
                                    {barchartData.map((category, index) => (
                                        <div
                                            key={index}
                                            className="data-card-small units-count-text bordered"
                                            style={{
                                                borderLeft: category.category === "UNDEFINED" ? '2px solid var(--gray-400)' : '',
                                            }}
                                        >
                                            <div className="units-count-label">
                                                <Trans i18nKey={category.category} />
                                            </div>
                                            <div className="data-card-count-percentage">
                                                <span>{visualizeActivityOverTimePercentage(sumArray(category.counts))}%</span>
                                                <span>|</span>
                                                <span>{sumArray(category.counts)}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashBoardSubPage;
