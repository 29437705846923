import React, { useState, useEffect } from "react";
import RecentPage from "../RecentPage/RecentPage";
import ScreenMonitoringPage from "../ScreenMonitoringPage/ScreenMonitoringPage";

const AdminOverviewPage = () => {
  // Array of components you want to cycle through
  const pages = [RecentPage, ScreenMonitoringPage];

  // In miliseconds
  const cycleTimerInterval = 30000;

  // State to track which component is currently displayed
  const [currentIndex, setCurrentIndex] = useState(0);

  // Set up an interval to change the component every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % pages.length);
    }, cycleTimerInterval); // Adjust the timing (in milliseconds) as needed

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [pages.length]);

  // Get the current component from the array
  const CurrentPage = pages[currentIndex];

  return <CurrentPage />;
};

export default AdminOverviewPage;
