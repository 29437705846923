import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './../../../contexts/UserContext';
import { getAvailableLocations } from './../../../hooks/unitDataHooks.js';
import { getUnitStateListener,  getUnitRecordsListener} from './../../../hooks/unitDataHooks';
import { useTranslation } from 'react-i18next';

import "./RecentPage.css";
import { use } from 'react';
import { LatestImageContextProvider, useLatestImage } from '../../../contexts/LatestImageContext.js';
import { getDisplayDateFromTimestamp } from '../../../common/dateTools.js';

const RecentPage = () => {
    const { t } = useTranslation();
    const [locations, setLocations] = useState([]);
    const [sortedCards, setSortedCards] = useState([]);
    const [locationUnits, setLocationUnits] = useState([]);
    const [evaluations, setEvaluations] = useState([]);
    const [unitRecentDataModels, setUnitRecentDataModels] = useState([]);
    const { userData } = useUser();
    
    const [statesForUnit, setStatesForLocation] = useState({});
    const [recordForUnit, setRecordForLocation] = useState({});
    const navigate = useNavigate();
    const {loadLatestImages, loadLatestImagesForRecords, latestImageCards, latestImageCardForUnit} = useLatestImage();

    const navigateToLocation = useCallback((locationId) => {
        //console.log("navigateToLocation")
        navigate(`locations/${locationId}`)
    }, [navigate])

    const specialTranslation = {
        "default": {
            "Normal": "Normalt træ"
        }
    }

    const getSpecialTranslation = (string, unit) => {
        if (specialTranslation[unit]) {
            if (specialTranslation[unit][string]) {
                return specialTranslation[unit][string]
            }
        } else {
            if (specialTranslation["default"][string]) {
                return specialTranslation["default"][string]
            }
        }

        return string
    }

    const getColorForUnit = (unitId) => {
        if (unitId && statesForUnit[unitId]) {
            const state = statesForUnit[unitId].worstState;
            switch (state) {
                case 'Ready':
                    return 'var(--success-500)';
                case 'Error':
                    return 'var(--error-500)';
                case 'Warning':
                    return 'var(--warning-500)';
                default:
                    return 'var(--gray-500)';
            }
        }
    }

    useEffect(() => {
        var unsubscribeCallbacks = []
        if (locations) {
            for (let i = 0; i < locations.length; i++) {
                if (!locations[i]) {
                    continue;
                }
                for (let j = 0; j < locations[i].units.length; j++) {
                    const unitId = locations[i].units[j];
                    const unsubscribeFirestore = getUnitStateListener(unitId, {
                        next: (log) => {
                            setStatesForLocation((prevStates) => {
                                
                                const locationId = locations[i].id;
                                let unitStatus;
                        
                                unitStatus = {
                                    newestTimestamp: log.timestamp,
                                    worstState: log.state,
                                };
                                
                                if (unitStatus.newestTimestamp < new Date(Date.now() - 24 * 60 * 60 * 1000)) {
                                    unitStatus.worstState = 'Disconnected';
                                }
                        
                                const newValue = { ...prevStates, [unitId]: unitStatus };
                                return newValue;
                            });
                        },
                        error: (error) => {
                            console.error("Error in unit state listener:", error);
                        }
                    });
                    unsubscribeCallbacks.push(unsubscribeFirestore)

                    const unsubscribeRecordsListener = getUnitRecordsListener(unitId, {
                        next: (record) => {
                            const recordData = {
                                record: record,
                                unit: unitId,
                                location: locations[i]
                            }
                            setRecordForLocation((prevValue) => {
                                const newValue = { ...prevValue, [unitId]: recordData };
                                return newValue;
                            });
                        },
                        error: (error) => {
                            console.error("Error in unit record listener:", error);
                        }
                    });
                    unsubscribeCallbacks.push(unsubscribeRecordsListener)
                }
            }
        }

        return () => {
            for (let i = 0; i < unsubscribeCallbacks.length; i++) {
                unsubscribeCallbacks[i]();
            }
        }
    }, [locations]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (userData && userData.companies) {
                    const locations = await getAvailableLocations(userData);
                    setLocations(locations);
                }
            } catch (error) {
                console.error("Error fetching locations: ", error);
            }
        };

        fetchData();
    }, [userData]);

    useEffect(() => {
        if (locations) {
            const newLocationUnits = []
            const evaluationsNewValue = []
            for (let location in locations) {
                let evaluations = []

                for (let unitKey in locations[location].units) {
                    const unit = locations[location].units[unitKey]
                    evaluations.push(0)

                    const locationUnit = {
                        location: locations[location],
                        unit: unit,
                    }

                    newLocationUnits.push(locationUnit)
                }
                
                evaluationsNewValue.push(evaluations)
            }

            setLocationUnits(newLocationUnits)
            setEvaluations(evaluationsNewValue)
        }
    }, [locations]);

    useEffect(() => {
        console.log("latestImageCards", latestImageCards)
    }, [latestImageCards]);

    useEffect(() => {
        console.log("latestImageCardForUnit", latestImageCardForUnit)
    }, [latestImageCardForUnit]);


    useEffect(() => {
        console.log("useEffect - recordForUnit", recordForUnit)
        
        loadLatestImagesForRecords(recordForUnit);
    }, [recordForUnit]);

    useEffect(() => {
        var newValue = []

        const sortLast = ["fierce-wolf", "happy-elephant"];

        var newValueTail = []
        for (let i = 0; i < latestImageCards.length; i++) {
            const latestImageCard = latestImageCards[i];

            if (sortLast.includes(latestImageCard.record.unit_id)) {
                newValueTail.push(latestImageCard)
            } else {
                newValue.push(latestImageCard)
            }
        }

        for (let i = 0; i < newValueTail.length; i++) {
            newValue.push(newValueTail[i])
        }

        setSortedCards(newValue);
    }, [latestImageCards]);
    
    return (
        <div className="RecentPage">
            <div className="wrapper">
                {sortedCards.map((latestImageCard, index) => (
                    <div key={index} className="card-wrapper" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <div 
                            className="login-container card-container" 
                            style={{ width: '100%', maxWidth: '620px', cursor: 'pointer' }}
                        >
                            <div className="card-header">
                                <div className="card-header-title">
                                    {/*locations[index].name} - {units[index].name}*/}
                                    {latestImageCard.location.name} - {latestImageCard.record.unit_id}
                                </div>
                            </div>
                            <div className="card-body" style={{ marginBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                                <figure className="img-wrapper">
                                    <img 
                                        className="poster-image"
                                        src={latestImageCard.url}
                                        width="588"
                                        height="250"
                                        alt="Poster"
                                    />
                                </figure>
                            </div>
                            <div className="card-body location-bar" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                                <div className="location-bar-left">
                                    <div className="date-line">
                                        {getDisplayDateFromTimestamp(latestImageCard.record.timestamp, userData?.language)}
                                    </div>
                                    <span className="units-count-text">
                                        <div className="units-count-label">{getSpecialTranslation(t(latestImageCard?.record?.eval ?? ""))}</div>
                                    </span>
                                </div>
                                <div className="location-bar-right">
                                    <div className="live-activity-wrapper">
                                        <div className="live-activity-dot" style={{ backgroundColor: getColorForUnit(latestImageCard.record.unit_id) }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default RecentPage;