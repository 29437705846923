export function getDateLocaleFromLanguage(language) {
    var userLocale = 'da-DK'
    
    if(language === 'se') {
        userLocale = 'sv-SE'
    }

    return userLocale
}

export const getDisplayDateFromDate = (date, language) => {
    const dateOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    };
    
    const dayOption = {
        weekday: 'long'
    }

    const timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    }
      
    const dateSegment = new Intl.DateTimeFormat(
        getDateLocaleFromLanguage(language), 
        dateOptions)
    .format(date);
    
    var formattedDateISO = dateSegment;
    if (language === "da" || language === undefined) {
        const [day, month, year] = dateSegment.split('.');
        formattedDateISO = `${year}-${month}-${day}`;
    }
  
    var daySegment = new Intl.DateTimeFormat(getDateLocaleFromLanguage(language), dayOption).format(date);
    daySegment = daySegment.charAt(0).toUpperCase() + daySegment.slice(1);
    
    const timeSegment = new Intl.DateTimeFormat(getDateLocaleFromLanguage(language), timeOptions).format(date).replace(/\./g, ":");
    
    const formattedDate = `${formattedDateISO} | ${daySegment} | ${timeSegment}`;

    return formattedDate
}

export const getDisplayDateFromTimestamp = (timestamp, language) => {
    return getDisplayDateFromDate(new Date(timestamp), language)
}