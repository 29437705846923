import React, { useEffect, useState } from 'react';
import email_sent_icon from './../../assets/icons/email_sent.svg';
import logo from "./../../assets/icons/Variant=ARIS Robotics, Colour=Green.svg"
import './../../App.css';
import "./LoginPage.css"
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './../../firebase';
import { Modal } from '@mui/material';
import ForgotPasswordDialog from './ForgotPasswordDialog/ForgotPasswordDialog';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from "react-router-dom";
import i18n from "i18next";

const LoginPage = ({ }) => {
    const [searchParams] = useSearchParams();
    const lang = searchParams.get("lang");
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [showForgotPasswordDialog, setShowForgotPasswordDialog] = useState(false);
    const [showEmailSentIcon] = useState(false);
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');

    function attemptLogin(email, password) {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredentials) => {
            })
            .catch((error) => {
                setLoginError(error.message);
            })
    }

        

    function loginPressed() {
        attemptLogin(email, password)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            attemptLogin(email, password)
        }
    };

    const forgotPasswordClicked = () => {
        setShowForgotPasswordDialog(true)
    }

    const closeDialog = () => {
        setShowForgotPasswordDialog(false)
    }

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [lang])

    return (
        <div 
            className="LoginPage"
            onKeyDown={handleKeyPress}
        >
            <img 
                className="main-logo"
                src={logo} 
                alt="Aris Robotics"
            />
            <div className="login-container card-container">
                <div className="card-header">
                    <div className="card-header-title" >
                        {t("Velkommen")}
                    </div>
                    <div className="card-header-actions">
                        {   showEmailSentIcon &&
                            <div 
                                className="email-sent-icon-wrapper"
                            >
                                <img 
                                    className="icon"
                                    src={email_sent_icon}
                                    alt="Email sent"
                                    data-tooltip-id="tooltip1"
                                    data-tooltip-content={t("Gendannelses-email sendt")}
                                    data-tooltip-place="top"
                                />
                                <Tooltip 
                                    id="email-sent-tooltip"
                                    placement="top"
                                    effect="solid"
                                    className="aris-tooltip"
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className="card-body">
                    <div className="login_card">
                        <input
                            className="aris-input"
                            type="email"
                            value={email}
                            onChange={(e)=>{setEmail(e.target.value)}}
                            placeholder="Email"
                        />
                        <input 
                            className="aris-input"
                            type="password"
                            value={password}
                            onChange={(e)=>{setPassword(e.target.value)}}
                            placeholder={t("Adgangskode")}
                        />
                        <div 
                            className="forgot-password"
                            onClick={() => forgotPasswordClicked()}
                        >
                            {t("Glemt kodeord?")}
                        </div>
                    </div>
                    <div className="card-actions">
                        <div className="login-error">
                            {loginError}
                        </div>
                        <button 
                            className="aris-button-primary"
                            type="submit"
                            onClick={loginPressed}
                        >
                            {t("Login")}
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                open={showForgotPasswordDialog}
                onClose={closeDialog}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    {
                        showForgotPasswordDialog 
                        ? <ForgotPasswordDialog onClose={closeDialog} />
                        : <></> 
                    }
                </div>
            </Modal>
        </div>
    );
}

export default LoginPage;
