import "./PointButton.css";
import { useTranslation } from "react-i18next";

const PointButton = () => {
    const { t } = useTranslation();
    
    return (
        <div className="PointButton">
            <div className="circle">
                <div className="point">
                </div>
            </div>

            <div className="text">
                {t("Udpeg objekt")}
            </div>
        </div>
    );
}

export default PointButton;