import React, { useEffect, useState, useCallback } from 'react';
import { Tooltip } from 'react-tooltip';
import { useSecondHeader } from './../../contexts/SecondHeaderContext';
import { useUnitDataCache } from './../../contexts/UnitDataCacheContext';
import './WadeReportPage.css'; // Import the corresponding CSS
import questionCircle from "./../../assets/icons/question-circle.svg"
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import increase_icon from "./../../assets/icons/increase_icon.svg"
import decrease_icon from "./../../assets/icons/decrease_icon.svg"
import DonutChart from '../../components/DonutChart/DonutChart.jsx';
import ActivationsOverTimeChart from '../../components/ActivationsOverTimeChart/ActivationsOverTimeChart.jsx';

const WadeReportPage = () => {
    const location = useLocation();

    const getMondayOfCurrentWeek = () => {
        const today = new Date();
        const dayOfWeek = today.getDay(); // 0 (Sun) to 6 (Sat)
        const diff = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek; // Calculate the difference to Monday
        const monday = new Date(today.setDate(today.getDate() + diff));
        monday.setHours(0, 0, 0, 0); // Set time to the start of the day
        return monday;
    }

    const { t } = useTranslation();
    const { unitId } = useParams();

    const [colors] = useState([
        '#74D5F2',
        '#BD92F4',
        '#81F3D5',
        '#FF8989',
        '#81ECF3',
        '#53C0FF',
        '#ED9BD2',
        '#FF89B0',
        '#90AAFF',
        '#81F3AD'
    ]);

    const year_start_unix = new Date(new Date().getFullYear(), 0, 1).getTime();
    const now_unix = new Date().getTime();
    const activationsFromDefault = year_start_unix;
    const activationsToDefault = now_unix;
    const activationsPrevFromDefault = new Date(new Date().getFullYear() - 1, 0, 1).getTime();
    const activationsPrevToDefault = new Date(new Date().getFullYear() - 1, 11, 31).getTime();
    const [activationsFrom, setActivationsFrom] = useState(activationsFromDefault);
    const [activationsTo, setActivationsTo] = useState(activationsToDefault);
    const [activationsPrevFrom, setActivationsPrevFrom] = useState(activationsPrevFromDefault);
    const [activationsPrevTo, setActivationsPrevTo] = useState(activationsPrevToDefault);

    // Default heatmap to show the current week
    const activityHeatmapFromDefault = getMondayOfCurrentWeek().getTime();
    const activityHeatmapToDefault = now_unix;
    const [activityHeatmapFrom, setActivityHeatmapFrom] = useState(activityHeatmapFromDefault);
    const [activityHeatmapTo, setActivityHeatmapTo] = useState(activityHeatmapToDefault);

    const activityOverTimeFromDefault = year_start_unix;
    const activityOverTimeToDefault = now_unix;
    const activityOverTimeUnitDefault = "year";
    const [activityOverTimeFrom, setActivityOverTimeFrom] = useState(activityOverTimeFromDefault);
    const [activityOverTimeTo, setActivityOverTimeTo] = useState(activityOverTimeToDefault);
    const [activityOverTimeUnit, setActivityOverTimeUnit] = useState(activityOverTimeUnitDefault);

    const [timeUnit, setTimeUnit] = useState('år'); // Default to 'år' (year)

    const [activationsUnit, setActivationsUnit] = useState('year');

    const [isWadeReportMode, setIsWadeReportMode] = useState(false);
    const [isWadeReportTimeMonth, setIsWadeReportTimeMonth] = useState(null);

    const [dountValues, setDonutValues] = useState([]);

    const {
        activationsCounts,
        activationsTotal,
        activationsRelativePercentage,
        activityOverTimeData,
        getActivationsData,
        getActivityOverTimeData,
        getActivityHeatmapData
    } = useUnitDataCache(); 

    const { currentUnit, getUnitData } = useSecondHeader();


    useEffect(() => {
        console.log("WadeReportPage- useEffect, activityOverTimeData", activityOverTimeData)
    }, [activityOverTimeData])

    useEffect(() => {
        const isWadeReportMode = true;
        const wadeReportTimeIsMonth = true;
        setIsWadeReportMode(isWadeReportMode)
        setIsWadeReportTimeMonth(wadeReportTimeIsMonth)

        const startOfLastCalendarMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).getTime();
        const endOfLastCalendarMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime();

        if (wadeReportTimeIsMonth) {
            setTimeUnit('måned');
            setActivationsUnit('month');
            setActivityOverTimeUnit('month');
            setActivationsFrom(startOfLastCalendarMonth);
            setActivationsTo(endOfLastCalendarMonth);
            
            setActivityOverTimeFrom(startOfLastCalendarMonth);
            setActivityOverTimeTo(endOfLastCalendarMonth);

            activationsDateSelected(startOfLastCalendarMonth, endOfLastCalendarMonth, 'month');
            activationsDateSelected(startOfLastCalendarMonth, endOfLastCalendarMonth, 'month');
        }
        
    }, [location])

    useEffect(() => {
        setDonutValues(activationsCounts.map((category, index) => {return category.count}))

        const indexOfUndefined = activationsCounts.findIndex((category) => category.category.includes('UNDEFINED'));
        if (indexOfUndefined !== -1) {
            colors.splice(indexOfUndefined, 0, '#A5A5A5');
        }
    }, [activationsCounts])

    const fetchHeatmapData = async () => {
        ////console.log("heatMap- fetchHeatmapData");
        ////console.log("heatMap- Current unit:", currentUnit);
        ////console.log("heatMap- From:", activityHeatmapFrom);
        if (!currentUnit) return;

        try {
            await getActivityHeatmapData(unitId, activityHeatmapFrom, activityHeatmapTo);
        } catch (error) {
            console.error('Error fetching activity heatmap data:', error);
        }
    }


    // Convert hex color to RGB
    const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        return {
            r: (bigint >> 16) & 255,
            g: (bigint >> 8) & 255,
            b: bigint & 255,
        };
    };

    const fetchActivityData = async () => {
        ////console.log("activations- Fetching activity data...");
        ////console.log("activations- Current unit:", currentUnit);
        if (!currentUnit) return;
        await getActivationsData(unitId, activationsFrom, activationsTo, activationsPrevFrom, activationsPrevTo);
    }

    const fetchActivityOverTimeData = async () => {
        ////console.log("activityOverTime- Fetching activity over time data...");
        ////console.log("activityOverTime- Current unit:", currentUnit);
        ////console.log("activityOverTime- From:", activityOverTimeFrom);
        ////console.log("activityOverTime- To:", activityOverTimeTo);
        if (!currentUnit || (activityOverTimeFrom === undefined) || (activityOverTimeTo === undefined) || activityOverTimeUnit === undefined) return;
        try {
            await getActivityOverTimeData(unitId, activityOverTimeFrom, activityOverTimeTo, activityOverTimeUnit);
        } catch (error) {
            console.error('activityOverTime-- Error fetching activity over time data:', error);
        }
    }

    // On mount
    useEffect(() => {
        ////console.log("Fetching data... (on mount)");

        if (!currentUnit) {
            ////console.log("Fetching unit data...", unitId);
            ////console.log("Unit id:")
            ////console.log(unitId)
            getUnitData(unitId)
        }

    }, []);

    useEffect(() => {
        fetchActivityData();

        const intervalId = setInterval(async () => {
            fetchActivityData();
        }, 10000);

        return () => {
            intervalId && clearInterval(intervalId);
        }
    }, [currentUnit, activationsFrom, activationsTo, activationsPrevFrom, activationsPrevTo, fetchActivityData]);
    
    useEffect(() => {
        fetchHeatmapData();

        const heatMapInterval = setInterval(async () => {
            fetchHeatmapData();
        }, 10000);

        return () => {
            heatMapInterval && clearInterval(heatMapInterval);
        }
    }, [currentUnit, activityHeatmapFrom, activityHeatmapTo]);

    useEffect(() => {
        ////console.log("activityOverTime- useEffect, time unit", activityOverTimeUnit)
        fetchActivityOverTimeData();

        const intervalId = setInterval(async () => {
            fetchActivityOverTimeData();
        }, 10000);

        return () => {
            intervalId && clearInterval(intervalId);
        }
    }, [currentUnit, activityOverTimeFrom, activityOverTimeTo, activityOverTimeUnit]);


    const addBarBorderRadius = (barChartData) => {
        for (let dataIndex = 0, moreValue = true; moreValue; dataIndex++) {
            moreValue = false;
            for (let i = 0; i < barChartData.length; i++) {
                const data = barChartData[i].data;
                if (dataIndex < data.length) {
                    moreValue = true;
                    if (data[dataIndex].value !== 0) {
                        data[dataIndex] = { value: data[dataIndex].value };
                        break;
                    }
                }
            }
        }

        for (let dataIndex = 0, moreValue = true; moreValue; dataIndex++) {
            moreValue = false;
            for (let i = barChartData.length - 1; i >= 0; i--) {
                const data = barChartData[i].data;
                if (dataIndex < data.length) {
                    moreValue = true;
                    if (data[dataIndex].value !== 0) {
                        data[dataIndex] = {
                            value: data[dataIndex].value,
                            itemStyle: {
                                borderRadius: [50, 50, 0, 0],
                            },
                        };
                        break;
                    }
                }
            }
        }
    };

    const activationsDateSelected = async (start_unix, end_unix, time_unit) => {

        setActivationsFrom(start_unix);
        setActivationsTo(end_unix);

        const previousPeriodStart = new Date(start_unix);
        previousPeriodStart.setFullYear(previousPeriodStart.getFullYear() - (time_unit === "year" ? 1 : 0));
        previousPeriodStart.setMonth(previousPeriodStart.getMonth() - (time_unit === "month" ? 1 : 0));
        previousPeriodStart.setDate(previousPeriodStart.getDate() - (time_unit === "week" ? 7 : 0));

        // Calculate the end of the previous period so it is has the same length as the current period
        const prevEnd = new Date(end_unix);
        prevEnd.setFullYear(prevEnd.getFullYear() - (time_unit === "year" ? 1 : 0));
        prevEnd.setMonth(prevEnd.getMonth() - (time_unit === "month" ? 1 : 0));
        ////console.log("prev- prev end 1", prevEnd)
        prevEnd.setDate(prevEnd.getDate() - (time_unit === "week" ? 7 : 0));

        setActivationsPrevFrom(previousPeriodStart.getTime());
        ////console.log("prev- prev end", prevEnd)
        setActivationsPrevTo(prevEnd.getTime())

        setActivationsUnit(time_unit);
    };

    
    const getCategoryLabel = useCallback((category) => {
        return t(category);
    }, [t]);

    const sumArray = (array) => array.reduce((acc, curr) => acc + curr, 0);

    const formatDateForDateLine = (date) => {

        // Create an options object for the custom format
        const options = { day: 'numeric', month: 'long', year: 'numeric' };

        // Format the date using the specified locale and options
        const formattedDate = new Intl.DateTimeFormat('da-DK', options).format(date);

        return formattedDate
    }

    const getDateLineString = (from, to) => {
        return `${formatDateForDateLine(new Date(from))} - ${formatDateForDateLine(new Date(to))}`
    }

    return (
        <div className="WadeReportPage">
        <div className="date-line">{getDateLineString(activityOverTimeFrom, activityOverTimeTo)}</div>
            <div className="wrapper">
                <div className="grid-wrapper">
                    <div 
                        className="card-container activations-card"
                    >
                        <div className="card-header">
                            <div className="card-header-title">
                                {t("Aktiveringer")}
                            </div>
                        </div>
                        <div style={{display: "flex", flex: 1, flexDirection: "column", height: "100%"}}>
                            <div className="card-body top-bar" style={{fontSize: 32, position: "relative"}}>
                                <p style={{margin: 0}}>
                                    {
                                        activationsTotal()
                                    }
                                </p>
                                { !isWadeReportMode && (
                                    <img 
                                        className="question-icon" 

                                        data-tooltip-id="tooltip1"
                                        data-tooltip-content={t("DASHBOARD.ACTIVATIONS_?")}
                                        data-tooltip-place="top"

                                        style={{top: 8, right: 8}}
                                        src={questionCircle} alt="?" 
                                    />
                                )}
                                <Tooltip 
                                    id="tooltip1" 
                                    place="top" 
                                    effect="solid"
                                    className="aris-tooltip questionMarkTooltip"
                                />
                                <div className="units-count-text ">
                                    <p className="units-count-label" style={{margin: 0}}>
                                        { isFinite(activationsRelativePercentage) &&
                                        <span>
                                        {(activationsRelativePercentage === 0 ?
                                            <span>{t("Ingen ændring") + " "}</span>
                                        :
                                            <span>
                                                {activationsRelativePercentage > 0 
                                                ? 
                                                <span className="trend_icon">
                                                    <img 
                                                        src={increase_icon}
                                                        alt="Increase icon"
                                                    />
                                                </span>
                                                : 
                                                <span className="trend_icon">
                                                    <img 
                                                        src={decrease_icon}
                                                        alt="Decrease icon"
                                                    />
                                                </span>
                                                }
                                                {activationsRelativePercentage > 0 
                                                ? <span className="trend-label-increasing">
                                                    {Math.abs(activationsRelativePercentage)}% {t("mere") + " "}
                                                </span>
                                                : <span className="trend-label-decreasing">
                                                    {Math.abs(activationsRelativePercentage)}% {t("mindre") + " "}
                                                </span>
                                                }
                                            </span>
                                        )}
                                         {activationsRelativePercentage == 0 ? "" : " end " }
                                        </span>
                                        }

                                        { !isFinite(activationsRelativePercentage) &&
                                            <span>{t("Ingen data")}</span>
                                        }
                                        {activationsUnit == "week" ? " " + t("ugen") + " ": 
                                        activationsUnit == "month" ? " " + t("måneden") + " " 
                                        : " " + t("året") + " "}
                                        {t("før")}
                                    </p>
                                </div>
                            </div>

                            <div className="card-body bottom-bar" style={{justifyContent: "start"}}>
                                <div className="data-cards-wrapper">

                                    {
                                        activationsCounts.map((category, index) => (
                                            <div 
                                                key={index}
                                                className="data-card-large units-count-text bordered" 
                                                style={{borderLeft: index === (activationsCounts.length - 1) ? '2px solid var(--gray-400)' : '2px solid ' + colors[index % colors.length]}}
                                            >
                                                <div className="units-count-label">{getCategoryLabel(category.category)}</div>
                                                <div className="data-card-count">{category.count}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-container donut-card-wade-report">
                        <div className="card-header">
                            <div className="card-header-title">{t("Kategorifordeling")}</div>
                        </div>
                        <div className="donut-section-body-wade-report-month">
                            <DonutChart absoluteValues={dountValues} outerRadius={144} innerRadius={76} colors={colors}/>
                        </div>
                    </div>


                </div>
            </div>
                    <div className="barchart-wrapper">
                        <div className="card-container barchart-card">
                            <div className="card-header">
                                <div className="card-header-title">
                                    {t("Aktiveringer over tid")}
                                </div>
                            </div>

                            <div className="card-body">
                                <ActivationsOverTimeChart 
                                    activityOverTimeData={activityOverTimeData} 
                                    startDate={activityOverTimeFrom}
                                    colors={colors}
                                    chartHeight={220}
                                />
                            </div>
                        </div>
                    </div>
        </div>
    );
};

export default WadeReportPage;
