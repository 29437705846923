import {useContext, createContext, useState, useRef, useEffect} from 'react';

import { collection, getDocs, query, orderBy, limit, getDoc, doc } from 'firebase/firestore';
import {db, storage} from '../firebase';
import { getDownloadURL, ref } from 'firebase/storage';

const LatestScreenshotContext = createContext(null);

export const useLatestScreenshot = () => {
    return useContext(LatestScreenshotContext);
}

export const LatestScreenshotContextProvider = ({children}) => {
    const [latestScreenshotCards, setLatestScreenshotCards] = useState([]);
    const [imagesToCheckObjects, setImagesToCheckObjects] = useState([]);
    const imageRetryInterval = 1000

    const getScreenshotDataForUnit = async (unitLocation) => {
        try {
            const unit_id = unitLocation.unit
            const docRef = doc(db, 'units', unit_id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const unit = docSnap.data();

                if(unit.last_screenshot_name && unit.last_screenshot_timestamp) {
                    console.log("Screenshot data found")
                    console.log("Unit location:")
                    console.log(unitLocation)
                    let data = {
                        unit_id: unitLocation.unit,
                        imageName: unit.last_screenshot_name,
                        timestamp: unit.last_screenshot_timestamp,
                        location: unitLocation.location
                    }
    
                    return data;
                }
            }

            return undefined;
        } catch (error) {
            console.error("screenshots- Error fetching screenshots: ", error);
            return undefined;
        }
    }

    const loadLatestScreenshotsForUnits = async (unitLocations) => {
        console.log(unitLocations)
        for (let i = 0; i < unitLocations.length; i++) {
            const unitLocation = unitLocations[i];
            let data = await getScreenshotDataForUnit(unitLocation);
            if (data) {
                setImagesToCheckObjects(prevImagesToCheckObjects => [...prevImagesToCheckObjects, data]);
            }
        }
    }

    const onImageRetryTimer = async () => {
        setImagesToCheckObjects([]);
        for (let imageToCheckIndex = 0; imageToCheckIndex < imagesToCheckObjects.length; imageToCheckIndex++) {
            const imageToCheck = imagesToCheckObjects[imageToCheckIndex];
            const unitId = imageToCheck.unit_id;
            const imageName = imageToCheck.imageName;
            const timestamp = imageToCheck.timestamp;
            const imageRefPath = `unit_screenshots/${imageName}`;
            const imageRef = ref(storage, imageRefPath);

            try {
                let url = await getDownloadURL(imageRef)
                var exists = false;

                for (let screenshotCardsIndex = 0; screenshotCardsIndex < latestScreenshotCards.length && !exists; screenshotCardsIndex++) {
                    if (latestScreenshotCards[screenshotCardsIndex].unit === unitId) {
                        exists = true;

                        setLatestScreenshotCards(prevLatestScreenshotCards => prevLatestScreenshotCards.map(card => {
                            if (card.unit === unitId) {
                                return {
                                    ...card,
                                    screenshotUrl: url,
                                    timestamp: timestamp
                                }
                            }

                            return card;
                        }));
                    }
                }

                if (!exists) {
                    console.log("Adding new screenshot card")
                    console.log("Unit location:")
                    console.log(imageToCheck)
                    setLatestScreenshotCards(prevLatestScreenshotCards => [...prevLatestScreenshotCards, {
                        unit: imageToCheck.unit_id,
                        screenshotUrl: url,
                        timestamp: timestamp,
                        location: imageToCheck.location
                    }]);
                }
            } catch (error){
                setImagesToCheckObjects(prevImagesToCheckObjects => [...prevImagesToCheckObjects, imageToCheck]);
                console.error("Error getting download url for ", imageRefPath);
                console.log("error: ", error);
            };
        }
    }

    const useInterval = (callback, interval) => {
        const callbackRef = useRef();

        useEffect(() => {
            callbackRef.current = callback;
        }, [callback])

        useEffect(() => {
            const tick = () => {
                callbackRef.current();
            }

            if (interval !== null) {
                const id = setInterval(tick, interval);
                return () => clearInterval(id);
            }
        }, [interval]);
    }

    useInterval(() => {
        onImageRetryTimer();
    }, imageRetryInterval);

    return (
        <LatestScreenshotContext.Provider value={{
            loadLatestScreenshotsForUnits,
            latestScreenshotCards
        }}>
            {children}
        </LatestScreenshotContext.Provider>
    )
}